<template>
    <div class="register-container">
        <router-link to="/subsummery" class="btn" style="background-color:  #d30c0c">Daily</router-link>&nbsp;
        <router-link to="/subsummon" class="btn" style="background-color:#0000ff " @click="getDataFromSessionStorage()">Monthly </router-link>&nbsp;
        <router-link to="/subdr" class="btn" style="background-color:green ">Date Range </router-link>&nbsp;
        <br> <br>
        <div class="register-form-container">
            <form id="SubjectSummaryForm" @submit="handleSubmit" novalidate autocomplete="off" >
    <div class="heading">
        <h1>
               Monthly Summary
        </h1> 
    </div>
    <div class="form-group">
        <div class="row">
            <div class="col-sm-4">
                    <label for="iHead">Select Vote
                    </label>
                  <select v-model="subObj.headid" id="iHead" class="form-control" >
                        <option value="" selected disabled>
                                 Choose
                        </option>
                        <option v-for="ratehead in sabhaIncomeHead" :key="ratehead.rate_head_id"  
                            v-bind:value =[ratehead.emp_sb_rates,ratehead.sb_rate_head_name] >
                            {{ ratehead.emp_sb_rates  }} - {{ ratehead.sb_rate_head_name  }}
                        </option>
                    </select>
        </div>
                    <!-- <p class="error-mess" v-if="errIncomeObj.iheadErr.length > 0">{{ errIncomeObj.iheadErr[0] }}</p>  -->
            
                <div class="col-sm-4">
                    <label for="iStamp">Month
                    </label>
                    <input type="month" name="idate"  id="idate" class="form-control" 
                        v-model="subObj.idate" />
                </div>
                <div class="col-sm-4">
                    <br>
                    <input type="button" @click="onChange()" value="Generate" class="btn" id ="gen" />
                </div>
                <br>
            </div>
        </div>
       <!-- go:{{ dailyreport }} -->
    <!-- <div class="form-group">
                   
                       <p v-for="ratehead in sabhaIncomeHead" :key="ratehead.emp_sb_rates "  
                           v-bind:value =ratehead.emp_sb_rates  >
                          
                           <router-link for="iHead" class="btn" to="/report"> {{ ratehead.emp_sb_rates  }} - {{ ratehead.h_description  }}
                           </router-link>
                        </p>
              
               </div>     -->
               <br>
        <div id="Printcontent">
            <div v-if="topic" class="heading">
               <h4>{{ selectedYear }} වර්ෂයේ {{ selectedMonth }} වන මස "<b>{{ topic[1] }}</b>" සාරාංශය
               </h4>
            </div>
               <div class="row">
               <table style="width: 98%; text-align: center; border-width: 1px ; border-style: solid; border-color : #130f40; border-collapse: collapse;">
               <thead>
                   <tr>   
                        <th style="text-align: center; border-width: 1px ; border-style: solid; border-color : #130f40; border-collapse: collapse;">Date</th>
                       <th style="text-align: center; border-width: 1px ; border-style: solid; border-color : #130f40; border-collapse: collapse;">Income head</th>
                       <th style="text-align: center; border-width: 1px ; border-style: solid; border-color : #130f40; border-collapse: collapse;">Invoice Number</th>
                       <th style="text-align: center; border-width: 1px ; border-style: solid; border-color : #130f40; border-collapse: collapse;">Customer Name</th>
                       <th style="text-align: center; border-width: 1px ; border-style: solid; border-color : #130f40; border-collapse: collapse;">Address</th>
                       <th style="text-align: center; border-width: 1px ; border-style: solid; border-color : #130f40; border-collapse: collapse; width: 40%;">Description</th>
                       <th style="text-align: center; border-width: 1px ; border-style: solid; border-color : #130f40; border-collapse: collapse;">Amount</th>
                      <th style="text-align: center; border-width: 1px ; border-style: solid; border-color : #130f40; border-collapse: collapse;">stamp</th>
                       <th style="text-align: center; border-width: 1px ; border-style: solid; border-color : #130f40; border-collapse: collapse;">Discounts</th>
                       <th style="text-align: center; border-width: 1px ; border-style: solid; border-color : #130f40; border-collapse: collapse;">Vat(%)</th>
                       <th style="text-align: center; border-width: 1px ; border-style: solid; border-color : #130f40; border-collapse: collapse;">Status</th>
                       
                   </tr>
               </thead>
               <tbody>
                   <tr v-for=" r in dailyreport" :key="r.id">
                        <td style="text-align: center; border-width: 1px ; border-style: solid; border-color : #130f40; border-collapse: collapse;">{{ getCurrentDate(r.date)}}</td>
                       <td style="text-align: center; border-width: 1px ; border-style: solid; border-color : #130f40; border-collapse: collapse;">{{ r.sb_rate_head }}</td>
                       <td style="text-align: center; border-width: 1px ; border-style: solid; border-color : #130f40; border-collapse: collapse;">{{ r.invoice_num }}</td>
                       <td style="text-align: center; border-width: 1px ; border-style: solid; border-color : #130f40; border-collapse: collapse;">{{ r.cus_name }}</td>
                       <td style="text-align: center; border-width: 1px ; border-style: solid; border-color : #130f40; border-collapse: collapse;">{{ r.cus_address }}</td>
                       <td style="text-align: center; border-width: 1px ; border-style: solid; border-color : #130f40; border-collapse: collapse;">{{ r.description }}</td>
                       <td style="text-align: right; border-width: 1px ; border-style: solid; border-color : #130f40; border-collapse: collapse;">{{ $formatNumber(r.amount) }}</td>
                       <td style="text-align: right; border-width: 1px ; border-style: solid; border-color : #130f40; border-collapse: collapse;">{{ $formatNumber(r.stamp) }}</td>
                       <td style="text-align: right; border-width: 1px ; border-style: solid; border-color : #130f40; border-collapse: collapse;">{{ $formatNumber(r.discount)}}</td>
                       <td style="text-align: right; border-width: 1px ; border-style: solid; border-color : #130f40; border-collapse: collapse;">{{ $formatNumber(r.vat) }}</td>
                       <td style="text-align: right; border-width: 1px ; border-style: solid; border-color : #130f40; border-collapse: collapse;" v-if="r.invoice_status==1"> Canceled </td>
                       <td style="text-align: right; border-width: 1px ; border-style: solid; border-color : #130f40; border-collapse: collapse;" v-else> </td>
                     
                   </tr>
                   <tr>
                        <td colspan="5" style="text-align: center; border-width: 1px ; border-style: solid; border-color : #130f40; border-collapse: collapse;"><b>Total{{ len }}</b></td>
                        <td></td>
                        <td style="text-align: center; border-width: 1px ; border-style: solid; border-color : #130f40; border-collapse: collapse;"><b>{{ $formatNumber(total_amount) }}</b></td>
                        <td style="text-align: right; border-width: 1px ; border-style: solid; border-color : #130f40; border-collapse: collapse;"><b>{{ $formatNumber(total_stamp) }}</b></td>
                        <td style="text-align: right; border-width: 1px ; border-style: solid; border-color : #130f40; border-collapse: collapse;"><b>{{ $formatNumber(total_dis) }}</b></td>
                        <td style="text-align: right; border-width: 1px ; border-style: solid; border-color : #130f40; border-collapse: collapse;">{{  }}</td>
                   </tr>
               </tbody>
           </table>
        </div>
           </div>   
            </form>
            <div class="form-group">
                    <input type="button" value="Print" class="btn" @click="printContent"/>
                    
                </div>
            </div>
            </div>    
</template>
<script>
import axios from 'axios';
export default {
    name: 'SubSummaryMonth',

    data() {
       return {
            nic:"",
            sabha: "",
            userLevel:"",
            sabhaIncomeHead : undefined,
            subObj:{headid:"", idate:""},
            allIncomeHead:[],
            rate:"",
            topic:"",
            reportDate:"",
            total_amount:0,
            len:0,
            total_stamp:0,
            total_dis:0,
            selectedMonth:'',
            selectedYear:'',
            dailyreport:[],
            serverDate:'',
            formatcurrentDate: '',
       };
    },
created() {
       this.getDataFromSessionStorage()
        // this.matchRateHead()
     
   },
   methods: {
    // formattedDate() {
    //             let date = new Date()
    //             const year = date.getFullYear();
    //       const month = String(date.getMonth() + 1).padStart(2, '0');
    //       const day = String(date.getDate()).padStart(2, '0');
    //       return `${year}-${month}-${day}`;
    //           },

              getCurrentDate(sgetdate) {
    if (!sgetdate) return "Invalid Date"; // Handle null/undefined

    let date = new Date(sgetdate);

    // If the date is invalid, try parsing it explicitly
    if (isNaN(date.getTime())) {
        date = new Date(parseInt(sgetdate)); // If it's a timestamp
        if (isNaN(date.getTime())) return "Invalid Date"; // Still invalid? Return error
    }

    const options = { timeZone: 'Asia/Colombo', year: 'numeric', month: '2-digit', day: '2-digit' };
    const formatter = new Intl.DateTimeFormat('en-CA', options);
    const parts = formatter.formatToParts(date);

    const year = parts.find(part => part.type === 'year').value;
    const month = parts.find(part => part.type === 'month').value;
    const day = parts.find(part => part.type === 'day').value;

    return `${year}-${month}-${day}`;
},
       async getDataFromSessionStorage(){
           const data = JSON.parse(sessionStorage.getItem('userData'))
           if(data){
                           this.nic = data.nic 
                           this.sabha = data.sabha
                           this.userLevel =data.userLevel

                       }
           let sabhaCode = this.sabha
           let Sabhadata = await axios.get('/subheads/'+ this.nic +"/"+ sabhaCode)
           this.sabhaIncomeHead = Sabhadata.data

           try {
                    const response = await axios.get('/getserverdate'); // Ensure correct API route
                    this.serverDate = response.data.serverDate; // Ensure correct response key
                    console.log(response.data);
                    this.formatcurrentDate=this.getCurrentDate(this.serverDate);
                } catch (error) {
                    console.error("Error fetching server date:", error);
                }

           // load report of all on page load
        // const current_date= new Date().toLocaleDateString('en-US', { timeZone: 'Asia/Colombo' })
        const current_date=  this.formatcurrentDate;
           this.reportDate = this.getCurrentDate(this.serverDate);
           let split_date= current_date.split('-')
           let currentyr= split_date[0]
           let currentmon = split_date[1]
            this.selectedYear= currentyr
            this.selectedMonth= currentmon
       let reportdata = await axios.get('/monvoterepo/'+this.sabha+"/"+currentmon+"/"+currentyr+"/"+this.nic )
       this.dailyreport = reportdata.data
        let tot_amount=0;
        let tot_stamp=0;
        let tot_dis=0;
       this.len =this.dailyreport.length
       for (let i=0;i<this.len;i++){
        if(this.dailyreport[i].invoice_status==0){
        tot_amount += parseFloat(this.dailyreport[i].amount||0)
        tot_stamp  += parseFloat(this.dailyreport[i].stamp||0)
        tot_dis  += parseFloat(this.dailyreport[i].discount||0)
        }
       }
       this.total_amount= tot_amount.toFixed(2)
       this.total_stamp= tot_stamp.toFixed(2)
       this.total_dis= tot_dis.toFixed(2)

      
        //    this.allIncomeHead = (await axios.get('/income_heads/')).data;
        // this.rate =this.allIncomeHead.h_description
       },
       async onChange(){
       // get data from tempory_invoice_save
       this.topic =this.subObj.headid
       this.reportDate = this.subObj.idate
       const selectedmon =  (this.subObj.idate).split("-")
      this.selectedMonth=selectedmon[1]
      this.selectedYear=selectedmon[0]
    //    let reportdata = await axios.get('/voterepobymon/'+ this.sabha +"/"+this.subObj.headid[0]+"/"+this.selectedMonth+"/"+this.selectedYear+ "/"+this.nic )
       //allvotemon
       let reportdata = await axios.get('/allvotemon/'+ this.sabha +"/"+this.subObj.headid[0]+"/"+this.selectedMonth+"/"+this.selectedYear)
       this.dailyreport = reportdata.data
        let tot_amount=0;
        let tot_stamp=0;
        let tot_dis=0;
       this.len =this.dailyreport.length
       for (let i=0;i<this.len;i++){
        if(this.dailyreport[i].invoice_status==0){
        tot_amount += parseFloat(this.dailyreport[i].amount||0)
        tot_stamp  += parseFloat(this.dailyreport[i].stamp||0)
        tot_dis  += parseFloat(this.dailyreport[i].discount||0)
        }
       }
       this.total_amount= tot_amount.toFixed(2)
       this.total_stamp= tot_stamp.toFixed(2)
       this.total_dis= tot_dis.toFixed(2)

       this.subObj.headid=""
       this.subObj.idate=""
    },
    printContent() {
            const prtHtml = document.getElementById('Printcontent').innerHTML;
               const WinPrint = window.open('', '', 'left=0,top=0,width=800,height=900,toolbar=0,scrollbars=0,status=0');
                WinPrint.document.write(`    
        ${prtHtml}
    `);
                WinPrint.focus();
                WinPrint.print();
                WinPrint.close();
        }
    },
   

    }
</script>

<style scoped>
.btn {
    background-color: #e68a00;   
    width: 15rem;
    height: 2.7rem;
    font-size: small;
    padding-top: 0.3rem;
    /* padding-bottom: 0.5rem; */
}
.ttable {
     
     width: 100%;
     border-width:2px;
     border-color : #130f40;
     /* border-style: solid; */
     font-size: 1.5rem;
     /* background-color :white; */
     /* padding-left: 3rem; */
      /* height: 3rem; */
 }
 th  {
     text-align: center;
}

.ttable, th, td {
border: 1px solid;
}
.register-container {
    padding: 1rem 1%;
    min-height: 75vh;
}

.register-container .register-form-container {
    background: #fff;

}

.register-container .register-form-container form {
    position: relative;
    left: 50%;
    transform: translate(-50%, 0%);
    /* max-width: 98rem; */
    width: 100%;
    box-shadow: 0 1rem 1rem rgba(0, 0, 0, 0.05);
    border: 0.1rem solid rgba(0, 0, 0, 0.2);
    padding: 3rem;
    border-radius: 0.5rem;
    animation: fadeUp 0.4s linear;
}

.register-container .register-form-container form h3 {
    padding-bottom: 0.5rem;
    font-size: 1.9rem;
    text-transform: uppercase;
    color: #130f40;
    /* margin: 0; */
    text-align: center;
    
}

.register-container .register-form-container form .form-control {
    margin: 0.7rem 0;
    border-radius: 0.5rem;
    background: #f7f7f7;
    /* padding: 2rem 1.2rem; */
    font-size: 1.5rem;
    color: #130f40;
    text-transform: none;
    width: 100%;
    border: margin-bottom; 
}

.register-container .register-form-container form .form-control1 {
    margin: 0.7rem 0;
    border-radius: 0.5rem;
    background: #f7f7f7;
    /* padding: 2rem 1.2rem; */
    font-size: 1.5rem;
    color: #130f40;
    text-transform: none;
    width: 40%;
    border: none;
    padding-bottom: 0.5rem;
}
.register-container .register-form-container form .form-radio {
    margin: 2rem 0%;
    border-radius: 0.5rem;
    background: #f7f7f7;
   padding-bottom: 1rem;
    font-size: 10rem;
    color: #130f40;

    width: 10%;
    border: none;
    margin-left: 10%;
}
.register-container .register-form-container form label {
    font-size: 1.5rem;
    margin: 0;
    padding: 0;
}

.register-container .register-form-container form span {
    font-size: 25px;
    /* padding-left: 5px;
    padding-right: 40px; */
    text-align: center;
    color: #620a0a;

}

.register-container .register-form-container form .btn {
    margin: 1rem 0;
    width: 30%;
    text-align: center;
    align-items: center;
    /* background: #620a0a; */
    background-color : #af74a7;
    
}

.register-container .register-form-container form p {
    padding-top: 0.75rem;
    font-size: 1rem;
    color: #666;
    margin: 0;
}

.register-container .register-form-container form p a {
    color: #27ae60;
}

.register-container .register-form-container form p a:hover {
    color: #130f40;
    text-decoration: underline;
}

.register-container .register-form-container form .form-group  {
    margin: 0;
}

.register-container .register-form-container form .form-group  .error-mess {
    font-size: 1rem;
    position: relative;
    color: rgb(243, 47, 47);
    margin: 0;
    padding: 0;
    width: 100%;

} 
.register-container .register-form-container form .hide {
    display: none;

} 
</style>