<template>
  <vue-basic-alert :duration="300" :closeIn="10000" ref="alert" />
  <div class="Shop-container">
      <div class="row1">
         <router-link to="/asesdash" class="close-btn" > Close </router-link>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                  <h1>Add Assessment tax PIV</h1>
     </div>
     <div class="Shop-form-container">
         <form id="addShopsForm"  novalidate autocomplete="off">
              <div class="heading">
                  <b><h3>Search Property</h3></b>
              </div>
              <h5><b>Select by Category</b></h5>
             <div class="row">
              <div class="col-sm-3">
                  
                 <label for="Splace">Ward
                 </label>
                     <div><select v-model="CatObj.ward" id="ward" class="form-control" >
                          <option value="" selected disabled>
                               Choose
                           </option>
                          <option v-for="m in savedWards" :key="m.ward_id" id="Hid"  
                          v-bind:value =m.ward_id>
                             <div>{{m.ward }}</div> 
                          </option>
                      </select>
                   </div> 

                 <p class="error-mess" v-if="errObj.wardErr.length > 0">{{ errObj.wardErr[0] }}</p>
             </div>
             <div class="col-sm-3">
                 <label for="street">Street
                 </label>
                     <div><select v-model="CatObj.street" id="street" class="form-control" >
                          <option value="" selected disabled>
                               Choose
                           </option>
                          <option v-for="m in savedStreets" :key="m.street_id" id="Hid"  
                          v-bind:value =m.street_id>
                             <div>{{m.street_name }}</div> 
                          </option>
                      </select>
                   </div> 

                 <p class="error-mess" v-if="errObj.stretErr.length > 0">{{ errObj.stretErr[0] }}</p>
             </div>
             <div class="col-sm-3">
                 <label for="cat">Main Category
                 </label>
                     <div><select v-model="CatObj.mainc" id="cat" class="form-control" >
                          <option value="" selected disabled>
                               Choose
                           </option>
                          <option v-for="m in maincate" :key="m.cat_id" id="Hid"  
                          v-bind:value =m.cat_id>
                             <div>{{m.category_name }}</div> 
                          </option>
                      </select>
                   </div> 

                 <p class="error-mess" v-if="errObj.maincErr.length > 0">{{ errObj.maincErr[0] }}</p>
             </div>
             <div class="Addbtn col-sm-3">
                 <label for="s">
                 </label>
                 <input type="button" value="Search" id="Addpay" class=" btn" @click="SearchByCat()"  /> 
             </div>
             </div>
             <div class="row">                    
                    <div class="col-sm-3">
                        <h5><b>search by property ID</b></h5>
                        <label for="usedn"> Property ID:
                        </label>
                        <div>
                        <input 
                        v-model="CatObj.propsearch" 
                        id="serchprop" 
                        list="sabaPropList" 
                        class="form-control"
                        placeholder="Choose or type a property ID"
                        />
                        
                        <datalist id="sabaPropList">
                        <option 
                            v-for="p in sabaProp" 
                            :key="p.property_id" 
                            :value="p.property_id">
                        </option>
                        </datalist>
                    </div>
                    </div>
                    <div class="Addbtn col-sm-3">
                   <br>
                   <input type="button" value="Search" id="secbtn" class=" btn" @click="SearchById()"  /> 
                    </div>
                </div>
                
              <!-- <div class="row">                    
                  <div class="col-sm-3">
                      <h5><b>Select by property ID</b></h5>
                      <label for="usedn"> Property ID:
                      </label>
                      <div>
                        <input type="text" id="serchprop" class="form-control" v-model="CatObj.propsearch" /> 
                        <select v-model="CatObj.propsearch" id="serchprop" class="form-control" >
                          <option value="" selected disabled>
                                      Choose
                                  </option>
                                  <option v-for="p in sabaProp" :key="p.property_id" id="Hid"  
                                  v-bind:value =p.property_id>
                                  <div>{{p.property_id }}</div> 
                                  </option>
                              </select> 
                          < <p class="error-mess" v-if="errObj.propErr.length > 0">{{ errObj.propErr[0] }}</p>
                      </div>
                    </div>
                    <div class="Addbtn col-sm-3"></div>
                    <br>
                    <input type="button" value="Search" id="secbtn" class=" btn" @click="SearchById()"  /> 
                  </div> -->
            <!-- </div> -->
          
         <br>
         <div v-if="showtbl" >
         <div   style="width: 100%; text-align: center;">
              <h3>Select a Property to Add PIV</h3>
          </div>
         <div class="row">
          <table style="width: 100%; text-align: left; font-size: small; border-width: 1px; border-style: solid; border-color: #130f40; border-collapse: collapse; margin-left: auto; margin-right: auto;">
             <thead>
                 <tr>   
                      <th>Index</th>
                     <th>Property ID</th>
                     <th>Assessment Num</th>
                     <th>Address</th>
                     <th>Rate</th>
                     <th>Select</th>
                 </tr>
             </thead>
             <tbody>
                 <tr v-for=" (s,index) in paginatedData" :key="index">
                  <td>{{ (currentPage - 1) * 10 + index + 1 }}</td>
                     <td>{{ s.property_id  }}</td>
                     <td>{{ s.asses_num  }}</td>
                     <td>{{ s.prop_address }}</td>
                     <td>{{ s.prop_rate }}</td>
                     <td> <input
                              type="radio"
                              name="propertySelection"
                              :value="s.property_id"
                              v-model="selectedProperty"
                               @change="populateCustomerDetails(s.property_id)"
                          /></td>
                 </tr>
             </tbody>
         </table>
         </div>
         <button class="fas fa-backward" @click="prevPage($event)" :disabled="currentPage === 1"></button>
           <span>{{ currentPage }} / {{ totalPages }}</span>
          <button class="fas fa-forward" @click="nextPage($event)" :disabled="currentPage === totalPages"></button>
      </div>
      <!-- <div v-if="selectedProperty" style="margin-top: 10px; text-align: center;">
          <p>Selected Property ID: {{ selectedProperty }}</p>
       </div> -->
         </form>
         <!-- piv -->
          <div class="register-container">
         <div class="register-form-container">
         <form id="userForm"  novalidate autocomplete="off">
          <div v-if="showtblpro">
             <div class="heading">
                 <h3>Invoice Details </h3>
             </div>
             <h4>Assesment Property Details</h4>
                 <div class="row">
                  <div class="col-sm-8">
                      <label for="cName">Name:</label>
                      <textarea name="cName" id="cName" class="form-control" v-model="cusObj.name" rows="2" readonly></textarea>
                  </div>
                     <div class="col-sm-4">
                         <label for="cAddress">Address:</label>
                          <textarea name="cAddress"  id="cAddress" class="form-control" v-model="cusObj.address" readonly ></textarea>
                     </div>
                 </div>
                <div class="form-group" >
                <table class="ttable"  >
                <thead>
                  <tr>
                    <th>Quarter</th>
                    <th>Payable Amount</th>
                    <th>Paid Amount</th>
                    <th>Fine</th>
                  </tr>
                </thead>
                <tbody>
                  <template v-for="p in propDetails" :key="p.property_id">
                <!-- First Quarter -->
                <tr>
                  <td style="text-align: left;"><b>First Quarter Amount</b></td>
                  <td>{{ p.q_one_val }}</td>
                  <td>{{ paymentData[p.property_id] && paymentData[p.property_id][1] ? paymentData[p.property_id][1][0] : 'Loading...' }}</td>
                  <td>{{ paymentData[p.property_id] && paymentData[p.property_id][1] ? paymentData[p.property_id][1][1] : 'Loading...' }}</td>
                </tr>

                <!-- Second Quarter -->
                <tr>
                  <td style="text-align: left;"><b>Second Quarter Amount</b></td>
                  <td>{{ p.q_two_val }}</td>
                  <td>{{ paymentData[p.property_id] && paymentData[p.property_id][2] ? paymentData[p.property_id][2][0] : 'Loading...' }}</td>
                  <td>{{ paymentData[p.property_id] && paymentData[p.property_id][2] ? paymentData[p.property_id][2][1] : 'Loading...' }}</td>
                </tr>

                <!-- Third Quarter -->
                <tr>
                  <td style="text-align: left;"><b>Third Quarter Amount</b></td>
                  <td>{{ p.q_three_val }}</td>
                  <td>{{ paymentData[p.property_id] && paymentData[p.property_id][3] ? paymentData[p.property_id][3][0] : 'Loading...' }}</td>
                  <td>{{ paymentData[p.property_id] && paymentData[p.property_id][3] ? paymentData[p.property_id][3][1] : 'Loading...' }}</td>
                </tr>

                <!-- Fourth Quarter -->
                <tr>
                  <td style="text-align: left;"><b>Fourth Quarter Amount</b></td>
                  <td>{{ p.q_four_val }}</td>
                  <td>{{ paymentData[p.property_id] && paymentData[p.property_id][4] ? paymentData[p.property_id][4][0] : 'Loading...' }}</td>
                  <td>{{ paymentData[p.property_id] && paymentData[p.property_id][4] ? paymentData[p.property_id][4][1] : 'Loading...' }}</td>
                </tr>
                </template>


                </tbody>
                </table>

                <br>
                </div>                  
                 <h4>Payment Details</h4>
                 <div class="form-group d-flex align-items-center mb-2">
                <label for="fqp" class="me-2" style="width: 180px;">Arrears/Excess Amount:</label>
                <!-- <input type="text" id="parr" class="form-control" v-model="cusObj.arre" style="width: auto;" :readonly="isReadonly"> &nbsp;&nbsp;&nbsp; -->
                <input type="text" id="parr" class="form-control" v-model="cusObj.arre" style="width: auto;"> &nbsp;&nbsp;&nbsp;
              
              </div> 
              <div id="paymentForm"  v-if="parseFloat(this.cusObj.arre) >0" class="form-group d-flex align-items-center mb-2"> 
                <label for="finevot" style="width: 120px;">Arrears Vote:</label>
                    <select v-model="cusObj.arreasvot" id="arrvote" class="form-control">
                      <option value="" selected disabled>
                              Choose
                      </option>
                      <option v-for="ratehead in sabhaIncomeHead" :key="ratehead.emp_sb_rates"  
                          v-bind:value =[ratehead.emp_sb_rates]  >
                            {{ ratehead.emp_sb_rates  }} - {{ratehead.sb_rate_head_name}}
                      </option>
                  </select>
                  <p class="error-mess" v-if="cusErrObj.arreheadErr.length > 0"> {{ cusErrObj.arreheadErr[0] }} </p>    
              </div>
           
              <!-- hidden if arreears is available -->
              <div  v-if="parseFloat(this.cusObj.arre) ==0 || parseFloat(this.cusObj.arre) <0"> 
                 <div class="form-group d-flex align-items-center mb-2">
                  <label for="checkboxannual" style="width: 200px;"><b>Pay Full Annual Amount</b></label>&nbsp;
                  <input type="checkbox" id="checkboxannual" v-model="Annualchecked"  @change="handleCheckboxChange"  />
                  <!-- <p class="error-mess" v-if="cusErrObj.monthendErr.length > 0">{{ cusErrObj.monthendErr[0] }}</p> -->
                  
                </div>
                <p v-if="errorMessage" class="text-danger">{{ errorMessage }}</p>
                <div class="form-group" id="yrvalforann" v-show="Annualchecked">
                  <label for="fqp" class="me-2" style="width: 180px;">Yearly Value:</label>
                  <input type="text" id="annval" class="form-control" v-model="cusObj.yearlyval" style="width: auto;" readonly />
                </div> 
                <div class="form-group " id="discforann" v-show="Annualchecked">
                  <label for="fqp" class="me-2" style="width: 180px;">Discount Value:</label>
                  <input type="text" id="anndis" class="form-control" v-model="cusObj.discval" style="width: auto;" readonly />
                </div> 

             <div>

              <div>
                <br>
                <div v-bind:hidden="Annualchecked">
                <div class="form-group d-flex align-items-center mb-2">
                <label for="checkbox" style="width: 100px;"><b>Tax Relief given</b></label>&nbsp;
                <input type="checkbox" id="checkbox" v-model="Relchecked" style="width: 70px;" />
              </div>
              <div class="form-group relief-row " id="relief" v-show="Relchecked">
                <label for="frel">Relief Percentage given(%) :&nbsp; </label>
                <input type="text" id="ream" v-model="cusObj.relper" />
                <input type="button" value="Calculate" id="calbtn" @click="calculateRelivedPayments" style="width: auto;" readonly/>
              </div>
              <br>  
<!-- Headings -->
<div class="form-group d-flex align-items-center mb-2 fw-bold">
  <label class="me-2" style="width: 180px;"></label>
  <label class="me-2" style="width: 180px;"><b>Quarter Payments</b></label>
  <label class="me-2" style="width: 180px;"><b>Discount</b></label>
  <label class="me-2" style="width: 180px;"><b>Fine</b></label>
  <label class="me-2" style="width: 200px;"><b>Amount to Pay</b></label>
  <label class="me-2" style="width: auto;"><b>Pay</b></label>
</div>

<!-- First Quarter Payment Row -->
<div class="form-group d-flex align-items-center mb-2">
  <label for="fqp" class="me-2" style="width: 180px;">First Quarter Payment:</label>
  <input type="text" id="fqpay" class="form-control me-2" v-model="cusObj.fq" style="width: auto;" readonly/>&nbsp;
  <input type="text" id="fqdisc" class="form-control me-2" :value="calculatedDiscount"  placeholder="Discount" style="width: auto;" readonly/>&nbsp;
  <input type="text" id="fqfine" class="form-control me-2" :value="calculatedFine" placeholder="Fine" style="width: auto;" readonly />&nbsp;
  <input type="text" id="fqamt" class="form-control" :value="calculatedAmount" placeholder="Amount to Pay" style="width: auto;" readonly/>
  <input type="checkbox" id="checkboxqone" class="form-control" v-model="qonecheck"  @change="qoneChecked" style="width: 5%;"/>
</div>
<p v-if="qOneErrorMessage" class="text-danger">{{ qOneErrorMessage }}</p>

<!-- Second Quarter Payment Row -->
<div class="form-group d-flex align-items-center mb-2">
  <label for="sqp" class="me-2" style="width: 180px;">Second Quarter Payment:</label>
  <input type="text" id="sqpay" class="form-control me-2" v-model="cusObj.sq" style="width: auto;" readonly/>&nbsp;
  <input type="text" id="sqdisc" class="form-control me-2" :value="secondQuarterDiscount" placeholder="Discount" style="width: auto;" readonly/>&nbsp;
  <input type="text" id="sqfine" class="form-control me-2" :value="secondQuarterFine" placeholder="Fine" style="width: auto;" readonly/>&nbsp;
  <input type="text" id="sqamt" class="form-control" :value="secondQuarterAmount" placeholder="Amount to Pay" style="width: auto;" readonly/>
  <input type="checkbox" id="checkboxqtwo" class="form-control" v-model="qtwocheck"  @change="qtwoChecked" style="width: 5%;" />
</div>
<p v-if="qtwoErrorMessage" class="text-danger">{{ qtwoErrorMessage }}</p>

<!-- Third Quarter Payment Row -->
<div class="form-group d-flex align-items-center mb-2">
  <label for="tqp" class="me-2" style="width: 180px;">Third Quarter Payment:</label>
  <input type="text" id="tqpay" class="form-control me-2"  v-model="cusObj.tq" style="width: auto;" readonly/>&nbsp;
  <input type="text" id="tqdisc" class="form-control me-2" :value="thirdQuarterDiscount"  placeholder="Discount" style="width: auto;" readonly/>&nbsp;
  <input type="text" id="tqfine" class="form-control me-2"  :value="thirdQuarterFine"  placeholder="Fine" style="width: auto;" readonly/>&nbsp;
  <input type="text" id="tqamt" class="form-control" :value="thirdQuarterAmount" placeholder="Amount to Pay" style="width: auto;" readonly/>
  <input type="checkbox" id="checkboxqthree" class="form-control" v-model="qthrecheck"  @change="qthreeChecked" style="width: 5%;"  />
  
</div>
<p v-if="qthreeErrorMessage" class="text-danger">{{ qthreeErrorMessage }}</p>

<!-- Fourth Quarter Payment Row -->
<div class="form-group d-flex align-items-center mb-2">
  <label for="foqp" class="me-2" style="width: 180px;">Fourth Quarter Payment:</label>
  <input type="text" id="foqpay" class="form-control me-2" v-model="cusObj.foq" style="width: auto;" readonly/>&nbsp;
  <input type="text" id="foqdisc" class="form-control me-2"  :value=" fourthQuarterDiscount" placeholder="Discount" style="width: auto;" readonly/>&nbsp;
  <input type="text" id="foqfine" class="form-control me-2" :value=" fourthQuarterFine"  placeholder="Fine" style="width: auto;" readonly/>&nbsp;
  <input type="text" id="foqamt" class="form-control"  :value=" fourthQuarterAmount" placeholder="Amount to Pay" style="width: auto;" readonly/>
  <input type="checkbox" id="checkboxqfour" class="form-control" v-model="qfourcheck"  @change="qfourChecked" style="width: 5%;"  />
</div>
<p v-if="qthreeErrorMessage" class="text-danger">{{ qfourerrorMessage }}</p>

<!-- Total Row -->
<div class="form-group d-flex align-items-center mb-2" >
    <label for="foqp" class="me-2" style="width: 180px;font-size:medium;text-align: center;">
      <b>Total:</b>
    </label>
    <input type="text" id="totalqpay" class="form-control me-2" :value="totalQuarterPayment" style="width: 180px; " readonly />&nbsp;
    <input type="text" id="totaldis" class="form-control me-2" :value="totalDiscount" style="width: 180px; font-weight: bold;" readonly />&nbsp;
    <input type="text" id="totalfine" class="form-control me-2" :value="totalFine" style="width: 180px; font-weight: bold;" readonly />&nbsp;
    <input type="text" id="totalamount" class="form-control me-2" :value="totalAmount" style="width: 180px; font-weight: bold;" readonly />
  </div>
  <div class="form-group col-sm-12 d-flex align-items-center">
    <div>
      <label for="fineam">Fine Amount:</label>
      <input type="text" id="desc" class="form-control" :value="totalFine" readonly/>
    </div>
  
    <div class="form-group col-sm-6" v-if="totalFine !== '0.00'">
  <label for="finevot">Fine Vote:</label>

      <select v-model="cusObj.finevot" id="finevote" class="form-control">
        <option value="" selected disabled>
                Choose
        </option>
        <option v-for="ratehead in sabhaIncomeHead" :key="ratehead.emp_sb_rates"  
            v-bind:value =[ratehead.emp_sb_rates]  >
              {{ ratehead.emp_sb_rates  }} - {{ratehead.sb_rate_head_name}}
        </option>
    </select>
    <p class="error-mess" v-if="cusErrObj.fineheadErr.length > 0"> {{ cusErrObj.fineheadErr[0] }} </p>    
</div>

</div>
<!-- below div is the show/hide for arrears -->
</div>

<div class="form-group" >
  <div class="me-3" >
      <label for="amountpay">Payment for Quarter:</label>
      <input type="text" id="qam" class="form-control"  :value="cusObj.payamountqaurter" readonly/>
    </div>
                 <label for="iHead">Income Head:
                 </label>
               <select v-model="cusObj.headid" id="iHead" class="form-control">
                     <option value="" selected disabled>
                              Choose
                     </option>
                     <option v-for="ratehead in sabhaIncomeHead" :key="ratehead.emp_sb_rates"  
                         v-bind:value =[ratehead.emp_sb_rates]  >
                           {{ ratehead.emp_sb_rates  }} - {{ratehead.sb_rate_head_name}}
                     </option>
                 </select>
                 <p class="error-mess" v-if="cusErrObj.iheadErr.length > 0">{{ cusErrObj.iheadErr[0] }}</p> 
</div>

              <!-- <div class="form-group">
                  <label for="desc">Description:</label>
                  <input type="text" id="desc" class="form-control" v-model="cusObj.descr" />
                  <p class="error-mess" v-if="cusErrObj.descrErr.length > 0">{{ cusErrObj.descrErr[0] }}</p> 
              </div> -->
            </div>
          </div></div>

          <div class="form-group">
            <!-- <div class="form-group" v-if="parseFloat(this.cusObj.arre) <0 || parseFloat(this.cusObj.arre) ==0"> -->
                 <label for="ampuntpaid">Amount Paid:
                 </label>
                 <!-- <input id="amountpaidget" type="text"  class="form-control" :value="amountpaid"  /> -->
                 <input id="amountpaidget" type="text"  class="form-control"  v-model="amountpaid" />
                 <p class="error-mess" v-if="cusErrObj.amountpaidErr.length > 0">{{ cusErrObj.amountpaidErr[0] }}</p> 
          </div>
         
              <div class="form-group">
          <label for="description"><b>Description:</b></label>
        <!-- <textarea id="descriptiontext" class="form-control" rows="10" v-model="descriptionText" ></textarea> -->
        <textarea 
            id="descriptiontext" 
            class="form-control" 
            rows="6" 
            v-model="descriptionInput">
          </textarea>
        </div>
             
              <input type="button" value="Add" id="Addpay" class=" btn" @click="addToTemp" /> 
   </div>          
            
           <!--.------------------------------- view table--------------------------------------->
              <br> 
              <div   style="width: 100%; text-align: center;">
              <h3>Added PIV</h3>
          </div>
             <div class="row">
             <table class="ttable" >
             <thead>
                 <tr>   
                     <th>NIC/Ref: No</th>
                     <th>Income head</th>
                     <th>Description</th>
                     <th>Amount</th>
                     <th>Stamp fee</th>
                     <th>Discounts</th>
                     <th>Vat(%)</th>
                     <th>Remove</th>
                 </tr>
             </thead>
             <tbody>
                 <tr v-for=" r in allreceipt" :key="r.id">
                      <td>{{ r.cus_nic }}</td>
                     <td>{{ r.sb_rate_head }}</td>
                     <td style="width: 30%; text-align: left" v-html="r.description.replace(/\n/g, '<br>')"></td>
                     <td>{{ $formatNumber(r.amount) }}</td>
                     <td>{{ $formatNumber(r.stamp) }}</td>
                     <td>{{ $formatNumber(r.discount) }} </td>
                     <td>{{ $formatNumber(r.vat) }}</td>
                     <td><input type="button" @click="deleteReceipt(r.shopdid);" value="X" class=" bbtn"></td>
                    
                 </tr>
             </tbody>
         </table>
         </div>
         </form>
     </div>
     </div>
</div>
</div>
<!-- end -->
</template>

<script>
import axios from "axios";
import VueBasicAlert from 'vue-basic-alert'
import { mapMutations } from "vuex";
import { mapState } from "vuex";

export default {

  name: "AssessPIV",

  data() {
     return {
         nic:"",
         sabha: "",
         userLevel:"",
         CatObj : { ward:"", street:"",  mainc:"" ,propsearch:""},
         errObj:{ wardErr:[], stretErr:[],maincErr:[]},
         savedWards:[],
         savedStreets:[],
         itemsPerPage: 10,
          currentPage: 1,
          maincate:[],
          sabaProp:[],
          propertydetails:[],
          showtbl:false,showtblpro:false,
          selectedProperty: null,
          cusObj:{name:"",address:"",headid:"",descr:"",arre:"",relper:"0",fq:"",sq:"",tq:"",foq:"",fqDiscount:"",sqDiscount:"",yearlyval:"",discval:"",payamountqaurter:"",finevot:"",arreasvot:""},
          cusErrObj:{nameErr:[],addErr:[],headidErr:[],descrErr:[],monthendErr:[],iheadErr:[],fineheadErr:[],arreheadErr:[],amountpaidErr:[]},
          amountpaid:0,
          ownerdetails:[],
          sabhaIncomeHead:[],
          propDetails:[],
          paymentData: {}, // Store paid amount and fine for each property and quarter
          response:[],
          responseData:[],
          Relchecked: false,
          propertyde:[],
          Annualchecked:false,
          errorMessage: "",
          qonecheck:false,
          qOneErrorMessage:"",
          qtwoErrorMessage:"",
          qtwocheck:false,
          qthreeErrorMessage:"",
          qthrecheck:false,
          qfourerrorMessage:"",
          qfourcheck:false,
          allreceipt:[],
          arrearsAmount:0,
          pamentArreas:[],
          isReadonly: true,
          notpaidQuaters:[],
          assnum:'',
          asspropId:0,totalqtpayment:0,
          descriptionInput: '',
          assdiscount:0,
          usedNum:'',
          serverDate:'',
     }; 
 },
 created() {
     this.getDataFromSessionStorage()

 },

 computed:{
     ...mapState(["user"]),
     amountpaidget(){
      let ampaid=0;
        if (parseFloat(this.cusObj.arre) > 0) {
          ampaid=parseFloat(this.cusObj.arre).toFixed(2)
        } 
        else {
          ampaid=parseFloat(this.cusObj.payamountqaurter).toFixed(2)
        }
          
        return ampaid
     },
     descriptionText() {
  let description = '';
let totalqtpayment=this.calQuaPay();


    description += `Ass Num: ${this.assnum}   `;
    description += `PID: ${this.asspropId}   Used Num: ${this.usedNum}\n`;
    // description += `Used Number: ${this.usedNum}\n`;

    if (parseFloat(this.cusObj.arre) > 0) {
    
    description += `Arrears/Excess Amount: ${parseFloat(this.cusObj.arre).toFixed(2)}\n`;
    } 

    if (parseFloat(this.cusObj.arre) <0){
      description += `Previous Excess Amount: ${parseFloat(this.cusObj.arre).toFixed(2)}\n`;
      // description += `Yearly Value: ${parseFloat(this.cusObj.yearlyval).toFixed(2)}\n`;
      // description += `Discount Amount: ${parseFloat(this.cusObj.discval).toFixed(2)}\n`;
      
    }
    if (this.Annualchecked) {
      description += `Yearly Value: ${parseFloat(this.cusObj.yearlyval).toFixed(2)}\n`;
      description += `Discount Amount: ${parseFloat(this.cusObj.discval).toFixed(2)}\n`;
        }

    
    const quarters = [
      { name: 'Q1', payment: this.cusObj.fq, discount: this.calculatedDiscount, fine: this.calculatedFine, amount: this.calculatedAmount },
      { name: 'Q2', payment: this.cusObj.sq, discount: this.secondQuarterDiscount, fine: this.secondQuarterFine, amount: this.secondQuarterAmount },
      { name: 'Q3', payment: this.cusObj.tq, discount: this.thirdQuarterDiscount, fine: this.thirdQuarterFine, amount: this.thirdQuarterAmount },
      { name: 'Q4', payment: this.cusObj.foq, discount: this.fourthQuarterDiscount, fine: this.fourthQuarterFine, amount: this.fourthQuarterAmount }
    ];

    const totalSummary = {
      payments: this.cusObj.payamountqaurter,
      discounts: this.totalDiscount,
      fines: this.totalFine,
      amount: this.totalAmount
    };
  
    quarters.forEach(quarter => {
      let quarterText = `${quarter.name}: `;
      let hasData = false;

      // Check for non-zero values
      if (quarter.amount && parseFloat(quarter.amount) !== 0) {
        quarterText += `Payment : ${quarter.payment || "N/A"}`;
        // this.totalqtpayment+=parseFloat(quarter.payment);
        hasData = true;
      }
      if (quarter.discount && parseFloat(quarter.discount) !== 0) {
        quarterText += `, Discount : ${quarter.discount || "N/A"}`;
        hasData = true;
      }
  

      if (hasData) {
        description += `- ${quarterText}; `; // Add line break after each quarter
        // description += `- ${quarterText}\n`; // Add line break after each quarter
      }
    
    });


    if (totalqtpayment && parseFloat(totalqtpayment) !== 0) {
      description += `\nTotal Quater Payments= ${totalqtpayment || "N/A"}\n`;
    }
    if (totalSummary.discounts && parseFloat(totalSummary.discounts) !== 0) {
      description += `Total Discounts= ${totalSummary.discounts || "0"}\n`;
    }
   
    let totaltopaid=this.amountpaidget;
          let amounttopaid=parseFloat(this.amountpaid);
          let exce=0;
          if(amounttopaid>totaltopaid){
            exce=parseFloat(totaltopaid)-parseFloat(amounttopaid);
            description += `Balance forwarded= ${exce.toFixed(2) || "0"}\n`;
          }
          if(amounttopaid<totaltopaid){
            exce=parseFloat(totaltopaid)-parseFloat(amounttopaid);
            description += `Arrears forwarded= ${exce.toFixed(2) || "0"}\n`;
          }

  
  // }

  return description || "No data available.";
}
,

  paginatedData() {
      const startIndex = (this.currentPage - 1) * this.itemsPerPage;
      const endIndex = startIndex + this.itemsPerPage;
      
      // Check if this.data is an array before using slice method
      if (Array.isArray(this.propertydetails)) {
      return this.propertydetails.slice(startIndex, endIndex);
      } else {
      // Return empty array or handle loading state
      return [];
      }
},

totalPages() {
    return Math.ceil(this.propertydetails.length / this.itemsPerPage);
  },
  // isArrayEmpty(array) {
  //   return array.length === 0; // Returns true if the array is empty
  // },
  //first quarter
  calculatedDiscount() {
  if (!this.qonecheck || !this.cusObj.fq) return "";
  const currentDate = new Date(this.serverDate);
  return this.calQuarterDiscount(this.cusObj.fq, currentDate)[0];
},
calculatedAmount() {
  if (!this.qonecheck || !this.cusObj.fq) return "";
  const currentDate = new Date(this.serverDate);
  return this.calQuarterDiscount(this.cusObj.fq, currentDate)[1];
},
calculatedFine() {
  if (!this.qonecheck || !this.cusObj.fq) return "";
  const currentDate = new Date(this.serverDate);
  return this.calQuarterDiscount(this.cusObj.fq, currentDate)[2];
},

  //second quarter
  secondQuarterDiscount(){
    if (!this.qtwocheck || !this.cusObj.sq) return "";
    const currentDate = new Date(this.serverDate);
    return this.calSecondQuarterValues(this.cusObj.sq, currentDate)[0];
  },
  secondQuarterAmount() {
    if (!this.qtwocheck || !this.cusObj.sq) return "";
    const currentDate = new Date(this.serverDate);
    return this.calSecondQuarterValues(this.cusObj.sq, currentDate)[1];
  },
  secondQuarterFine() {
    if (!this.qtwocheck || !this.cusObj.sq) return "";
    const currentDate = new Date(this.serverDate);
    return this.calSecondQuarterValues(this.cusObj.sq, currentDate)[2];
  },
      //Third quarter
  thirdQuarterDiscount(){
    if (!this.qthrecheck || !this.cusObj.tq) return "";
    const currentDate = new Date(this.serverDate);
    return this.calThirdQuarterValues(this.cusObj.tq, currentDate)[0];
  },
  thirdQuarterAmount() {
    if (!this.qthrecheck || !this.cusObj.tq) return "";
    const currentDate = new Date(this.serverDate);
    return this.calThirdQuarterValues(this.cusObj.tq, currentDate)[1];
  },
  thirdQuarterFine() {
    if (!this.qthrecheck || !this.cusObj.tq) return "";
    const currentDate = new Date(this.serverDate);
    return this.calThirdQuarterValues(this.cusObj.tq, currentDate)[2];
  },
  //Fourth quarter
  fourthQuarterDiscount(){
    if (!this.qfourcheck || !this.cusObj.foq) return "";
    const currentDate = new Date(this.serverDate);
    return this.calFourthQuarterValues(this.cusObj.foq, currentDate)[0];
  },
  fourthQuarterAmount() {
    if (!this.qfourcheck || !this.cusObj.foq) return "";
    const currentDate = new Date(this.serverDate);
    return this.calFourthQuarterValues(this.cusObj.foq, currentDate)[1];
  },
  fourthQuarterFine() {
    if (!this.qfourcheck || !this.cusObj.foq) return "";
    const currentDate = new Date(this.serverDate);
    return this.calFourthQuarterValues(this.cusObj.foq, currentDate)[2];
  },
 
  // Dynamic calculations for totals
  totalQuarterPayment() {
    return (Number(this.cusObj.fq) + Number(this.cusObj.sq) + Number(this.cusObj.tq) + Number(this.cusObj.foq)).toFixed(2);
  },
  totalDiscount() {
    return (Number(this.calculatedDiscount) + Number(this.secondQuarterDiscount) +Number(this.thirdQuarterDiscount) + Number(this.fourthQuarterDiscount)).toFixed(2)
    // return Number(this.calQuarterDiscount[0]) + Number(this.calSecondQuarterValues[0]) + Number(this.calThirdQuarterValues[0]) + Number(this.calFourthQuarterValues[0]);
  },
  totalFine() {
    // return (this.calculatedFine || 0) + (this.secondQuarterFine || 0) + (this.thirdQuarterFine || 0) + (this.fourthQuarterFine || 0);
    return (Number(this.calculatedFine) + Number(this.secondQuarterFine) +Number(this.thirdQuarterFine) + Number(this.fourthQuarterFine)).toFixed(2)
  },
  totalAmount() {
    // return (this.calculatedAmount || 0) + (this.secondQuarterAmount || 0) + (this.thirdQuarterAmount || 0) + (this.fourthQuarterAmount || 0);
    return (Number(this.calculatedAmount) + Number(this.secondQuarterAmount) +Number(this.thirdQuarterAmount) + Number(this.fourthQuarterAmount)).toFixed(2)

  },
 },
 async mounted() {
  try {
      this.maincate = (await axios.get('/maincat/')).data
         //wards
         this.savedWards= (await axios.get('/wdbys/'+this.sabha)).data;
         //streets
         this.savedStreets = (await axios.get('/stbys/'+this.sabha)).data;

  } catch (error) {
      console.error("Error fetching data", error);
  }

  await this.loadPaymentData();
  // await this.loadArrears();

},
watch: {
  propDetails: {
  handler(newVal) {
    if (Array.isArray(newVal) && newVal.length > 0) {
      newVal.forEach(async (p) => {
        try {
          await this.loadPaymentData(p.property_id);
        } catch (error) {
          console.error(`Failed to load payment data for Property ID: ${p.property_id}`, error);
        }
      });
    }
  },
  immediate: true,
  deep: true
},

// propDetails: {
//   handler(newVal) {
//     if (newVal && newVal.length > 0) {
//       newVal.forEach(p => {
//         for (let qua = 1; qua <= 4; qua++) {
//           this.loadPaymentData(p.property_id, qua);
//           // this.loadArrears(p.property_id, qua)
//         }
//       });
//     }
//   },
//   immediate: true,
//   deep: true
// },
 totalAmount: 'updatePayAmountQuarter',
  totalFine: 'updatePayAmountQuarter',
  descriptionText(newVal) {
    this.descriptionInput = newVal;
  },
  // amountpaidget(newVal) {
  //   this.amountpaid = newVal;
  // }
},
 methods: {
     ...mapMutations(["setUser"]),
     calQuaPay() {
  let totalqtpayment = 0; // Start with the existing total

  if (this.qonecheck) totalqtpayment += parseFloat(this.cusObj.fq || 0);
  if (this.qtwocheck) totalqtpayment += parseFloat(this.cusObj.fq || 0);
  if (this.qthrecheck) totalqtpayment += parseFloat(this.cusObj.fq || 0);
  if (this.qfourcheck) totalqtpayment += parseFloat(this.cusObj.fq || 0);

  
  return totalqtpayment;
},
isFullyPaid(pid) {
  const paymentStatus = this.paymentData[pid];
  if (!paymentStatus) {
    // If there's no payment data for the property, return false
    return false;
  }

  // Check all quarters (1 to 4)
  const allPaid = ['1', '2', '3', '4'].every(
    (quarter) => paymentStatus[quarter]?.[0] === "Paid"
  );

  return allPaid;
},

async saveTemporyPayment(pid) {

  const currentYear = new Date(this.serverDate).getFullYear();
  const nextYear = currentYear + 1;
  const quarters = [
    {
      name: '1',
      payment: this.cusObj.fq,
      discount: this.calculatedDiscount,
      fine: this.calculatedFine,
      amount: this.calculatedAmount,
      isChecked: this.qonecheck,
    },
    {
      name: '2',
      payment: this.cusObj.sq,
      discount: this.secondQuarterDiscount,
      fine: this.secondQuarterFine,
      amount: this.secondQuarterAmount,
      isChecked: this.qtwocheck,
    },
    {
      name: '3',
      payment: this.cusObj.tq,
      discount: this.thirdQuarterDiscount,
      fine: this.thirdQuarterFine,
      amount: this.thirdQuarterAmount,
      isChecked: this.qthrecheck,
    },
    {
      name: '4',
      payment: this.cusObj.foq,
      discount: this.fourthQuarterDiscount,
      fine: this.fourthQuarterFine,
      amount: this.fourthQuarterAmount,
      isChecked: this.qfourcheck,
    },
  ];

  await this.loadPaymentData(pid); // Load the payment data first
  const isPaid = this.isFullyPaid(pid);
  if (isPaid) {
    if (this.Annualchecked) {
    // Aggregate data from all quarters
    const aggregatedData = {
      sb_code: this.sabha,
      property_id: this.ownerdetails[0]?.property_id || "",
      quarter_num: '5', // Representing annual payment
      paid_amount: this.cusObj.payamountqaurter,
      paid_date: this.formattedDate(),
      discount_given: this.cusObj.discval,
      paid_year: nextYear,
      fine_amount:'0',
      record_date: this.formattedDate(),

    };

    try {
      await axios.post("/savpaytemp/", aggregatedData);
      console.log('Saved aggregated annual payment data');
    } catch (error) {
      console.error('Failed to save aggregated annual payment data', error);
    }
  } else {
    // Process each quarter individually if Annualchecked is false
    for (const quarter of quarters) {
      if (quarter.isChecked) {
        const tempPaydata = {
          sb_code: this.sabha,
          property_id: this.ownerdetails[0]?.property_id || "",
          quarter_num: quarter.name,
          paid_amount: quarter.amount,
          paid_date: this.formattedDate(),
          discount_given: quarter.discount,
          paid_year: nextYear,
          fine_amount: quarter.fine,
          record_date: this.formattedDate(),
        };

        try {
          await axios.post("/savpaytemp/", tempPaydata);
          console.log(`Saved data for Quarter ${quarter.name}`);
        } catch (error) {
          console.error(`Failed to save data for Quarter ${quarter.name}`, error);
        }
      }
    }
  }


    console.log(`Property ${pid} is fully paid.`);
  } else {
    console.log('Annualchecked:', this.Annualchecked);
    if (this.Annualchecked) {
    // Aggregate data from all quarters
    const aggregatedData2 = {
      sb_code: this.sabha,
      property_id: this.ownerdetails[0]?.property_id || "",
      quarter_num: '5', // Representing annual payment
      paid_amount: this.cusObj.payamountqaurter,
      paid_date: this.formattedDate(),
      discount_given: this.cusObj.discval,
      paid_year:currentYear,
      fine_amount:'0',
      record_date: this.formattedDate(),

    };

    try {
      await axios.post("/savpaytemp/", aggregatedData2);
      console.log('Saved aggregated annual payment data');
    } catch (error) {
      console.error('Failed to save aggregated annual payment data', error);
    }
  } else {
    // Process each quarter individually if Annualchecked is false
    for (const quarter of quarters) {
      if (quarter.isChecked) {
        const tempPaydata = {
          sb_code: this.sabha,
          property_id: this.ownerdetails[0]?.property_id || "",
          quarter_num: quarter.name,
          paid_amount: quarter.amount,
          paid_date: this.formattedDate(),
          discount_given: quarter.discount,
          paid_year: currentYear,
          fine_amount: quarter.fine,
          record_date: this.formattedDate(),
        };

        try {
          await axios.post("/savpaytemp/", tempPaydata);
          console.log(`Saved data for Quarter ${quarter.name}`);
        } catch (error) {
          console.error(`Failed to save data for Quarter ${quarter.name}`, error);
        }
      }
    }
  }
    console.log(`Property ${pid} is not fully paid.`);
  }

},

     async getDataFromSessionStorage(){
         const data = JSON.parse(sessionStorage.getItem('userData'))
         if(data){
                         this.nic = data.nic 
                         this.sabha = data.sabha
                         this.userLevel =data.userLevel
                     }
              try{
              this.sabaProp = (await axios.get('/props/' +this.sabha)).data                       
          } catch (error) {
              console.error("Error fetching data", error);
          }
         this.maincate = (await axios.get('/maincat/')).data
         //wards
         this.savedWards= (await axios.get('/wdbys/'+this.sabha)).data;
         //streets
         this.savedStreets = (await axios.get('/stbys/'+this.sabha)).data;
         //incomeheads
         let Sabhadata = await axios.get('/subheads/'+ this.nic +"/"+ this.sabha)
         this.sabhaIncomeHead = Sabhadata.data
         this.allreceipt =  (await axios.get('/ownpiv/'+ this.nic +"/"+ this.sabha)).data
         try {
                    const response = await axios.get('/getserverdate'); // Ensure correct API route
                    this.serverDate = response.data.serverDate; // Ensure correct response key
                    console.log(response.data);
                    // this.formatcurrentDate=this.getCurrentDate(this.serverDate);
                } catch (error) {
                    console.error("Error fetching server date:", error);
                }

     },
     getWardName(ward_id) {
      const ward = this.savedWards.find(m => m.ward_id === ward_id);
      return ward ? ward.ward : 'Unknown';  // Return 'Unknown' if ward_id is not found
  },
  getStreetName(street_id) {
      const street = this.savedStreets.find(m => m.street_id === street_id);
      return street ? street.street_name : 'Unknown';
  },
  getCategoryName(cat_id) {
      const category = this.maincate.find(m => m.cat_id === cat_id);
      return category ? category.category_name : 'Unknown';
  },

nextPage(event) {
if (this.currentPage < this.totalPages) {
  this.currentPage++;
}
event.preventDefault();
},
prevPage(event) {
if (this.currentPage > 1) {
  this.currentPage--;
}
event.preventDefault();
},
//for saving piv
updatePayAmountQuarter() {

  if(parseFloat(this.cusObj.arre)>0){
    this.cusObj.payamountqaurter = parseFloat((this.totalAmount - this.totalFine).toFixed(2));
  }else if(parseFloat(this.cusObj.arre)<0){
    this.cusObj.payamountqaurter =(parseFloat(this.totalAmount)+parseFloat(this.cusObj.arre)).toFixed(2);
  }
  else{
    this.cusObj.payamountqaurter = parseFloat(this.totalAmount).toFixed(2);
  }
  },
  //delete all receipt by nic 
  async deleteReceipt(id){
      let result = confirm("This will delete all PIVs for this property. Confirm delete?")
      if(result == true){
        await axios.delete("/delallpiv/" + id);
        await axios.delete("/delalltempquatpay/" + id+"/"+ this.sabha);
        await axios.delete("/delexcess/" + id+"/"+ this.sabha);
        
      this.allreceipt =  (await axios.get('/ownpiv/'+ this.nic +"/"+ this.sabha)).data
      }
     },
  formattedDate() {
          let date = new Date(this.serverDate)
          const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const day = String(date.getDate()).padStart(2, '0');
    return `${year}-${month}-${day}`;
        },
//fectch both
  async fetchData(url) {
      try {
          const response = await axios.get(url);
          this.propertydetails = response.data;
          if (this.propertydetails.length > 0) {
          this.showtbl = true;
          } else {
          this.message = "No such record";
          this.showtbl = false;
          }
      } catch (error) {
          console.error("Error fetching details:", error);
          this.message = "Failed to load details. Please try again later.";
      }
      },

     resetCheckErr: function () {
      this.errObj.wardErr =[];
      this.errObj.stretErr=[];
      this.errObj.maincErr = [];
     },
     checkEmptyErr: function () {
         for (var typeErr in this.errObj) {
             if (this.errObj[typeErr].length != 0) {
                 return false;
             }
         }
         return true;
     },
     checkForm: function () {
         this.resetCheckErr();
         if (!this.CatObj.ward) {
             this.errObj.wardErr.push("required");
         }
         if (!this.CatObj.street) {
             this.errObj.stretErr.push("required");
         }
         // main categories
         if (!this.CatObj.mainc) {
             this.errObj.maincErr.push("required");
         }
     },
     async SearchByCat(e) {
          this.checkForm();
          if (!this.checkEmptyErr()) {
              e.preventDefault();
          } else {
              this.showtbl = false;
              this.showtblpro = false;
              this.qfourcheck=false;
        this.qthrecheck=false;
        this.qtwocheck=false;
        this.qonecheck=false;
          this.message = ""; // Clear any previous messages
          // this.propertydetails = []; // Clear previous data
          this.CatObj.propsearch=""
          this.selectedProperty= null
              const url = `/proplist/${this.sabha}/${this.CatObj.ward}/${this.CatObj.street}/${this.CatObj.mainc}`;
              await this.fetchData(url);
              
          }
           this.allreceipt = (await axios.get('/ownpiv/' + this.nic + "/" + this.sabha)).data;
      },
  async SearchById() {
    this.allreceipt = (await axios.get('/ownpiv/' + this.nic + "/" + this.sabha)).data;
        this.showtbl = false;
        this.showtblpro = false;
        this.qfourcheck=false;
        this.qthrecheck=false;
        this.qtwocheck=false;
        this.qonecheck=false;
      this.message = ""; // Clear any previous messages
      this.propertydetails = []; // Clear previous data
      this.CatObj.ward="",
      this.CatObj.street="",
      this.CatObj.mainc="",
      this.selectedProperty= null
      this.cusObj.arre=""
      //removing quarter payments text box values
  this.cusObj.fq=""
  this.cusObj.sq=""
  this.cusObj.tq=""
  this.cusObj.foq=""
  try {
      const url = `/sabapropid/${this.sabha}/${this.CatObj.propsearch}`;
      
      // Fetch data from API
      const response = await axios.get(url);

      if (response.data.length > 0) {
      this.propertydetails = response.data;
      this.showtbl = true;
      } else {
      this.message = "No such record";
      this.showtbl = false;
      this.qfourcheck=false;
        this.qthrecheck=false;
        this.qtwocheck=false;
        this.qonecheck=false;
      }
      this.allreceipt = (await axios.get('/ownpiv/' + this.nic + "/" + this.sabha)).data;
  } catch (error) {
      console.error("Error fetching details:", error);
      this.message = "Failed to load details. Please try again later.";
      this.showtbl = false;
      this.showtblpro = false;
      this.qfourcheck=false;
        this.qthrecheck=false;
        this.qtwocheck=false;
        this.qonecheck=false;
        this.cusObj = { payamountqaurter: "0", arre: "0.00", finevot: "", headid: "", descr: "" };
    this.amountpaid = '0';
    this.totalFine = "0.00";
  }
  },
  //selected property's details
  // async populateCustomerDetails(property) {
  //   // Populate the form fields with the selected property's details
    
  //   this.ownerdetails = (await axios.get('/proownerdetatails/'+property+"/"+this.sabha)).data;
  //   this.cusObj.name = this.ownerdetails.owner_name || "";
  //   this.cusObj.phone = this.ownerdetails.owner_contact || "";
  //   this.cusObj.address = this.ownerdetails.owner_address || "";
  // },
  async populateCustomerDetails(property) {
try {
  
  const response = await axios.get(`/prownerdetails/${property}/${this.sabha}`);

  if (response.data && response.data.length > 0) {
    this.ownerdetails = response.data;

    // Join multiple names into a single string (e.g., comma-separated)
    this.cusObj.name = this.ownerdetails.map(owner => owner.cus_name).join(', ') || "";

    // Assign the first address found
    this.cusObj.address = this.ownerdetails[0].prop_address || "";
this.assnum=this.ownerdetails[0].asses_num;
this.asspropId=this.ownerdetails[0].property_id;


  } else {
    console.warn("No owner details found.");
    this.$refs.alert.showAlert('error', 'No owner details found');
    this.cusObj = { name: "", address: "" }; // Clear form
    this.message = "No owner details available.";
  }

  //get property payment details to show in the table
  this.propDetails =  (await axios.get('/sabapropid/'+ this.sabha +"/"+ property)).data
  this.cusObj.arre=this.propDetails[0].arrears_val;
  this.cusObj.fq=this.propDetails[0].q_one_val;
  this.cusObj.sq=this.propDetails[0].q_two_val;
  this.cusObj.tq=this.propDetails[0].q_three_val;
  this.cusObj.foq=this.propDetails[0].q_four_val;
  this.usedNum=this.propDetails[0].used_num;
  this.showtblpro = true;
  this.Annualchecked=false;
  this.qfourcheck=false;
        this.qthrecheck=false;
        this.qtwocheck=false;
        this.qonecheck=false;

} catch (error) {
  console.error("Error fetching owner details:", error);
  this.message = "Failed to load owner details. Please try again later.";
}
// this.loadPaymentData()

},

async loadPaymentData(pid) {
  const currentYear = new Date(this.serverDate).getFullYear();

  try {
    // Check for annual payment (Quarter 5)
    const annualResponse = await axios.get(`/pqpayview/${pid}/${this.sabha}/5/${currentYear}`);
    const annualResult = annualResponse.data[0];

    if (annualResult) {
      // If annual payment exists, mark all quarters as "Paid"
      this.paymentData[pid] = {
        '1': ["Paid", "0.00"],
        '2': ["Paid", "0.00"],
        '3': ["Paid", "0.00"],
        '4': ["Paid", "0.00"],
      };
      return this.paymentData[pid];
    }

    // If no annual payment, check individual quarters
  
    for (let quarter = 1; quarter <= 4; quarter++) {
  try {
    const response = await axios.get(`/pqpayview/${pid}/${this.sabha}/${quarter}/${currentYear}`);
    const result = response.data?.[0]; // Ensure result is correctly accessed

    if (!this.paymentData[pid]) this.paymentData[pid] = {}; // Initialize pid entry if it doesn't exist

    if (result && result.paid_amount) {
      // Check if paid_amount exists and is valid
      this.paymentData[pid][quarter] = ["Paid",  parseFloat(result.fine_amount || 0).toFixed(2)];
    } else {
      // Mark as not paid if result is falsy or paid_amount is absent
      this.paymentData[pid][quarter] = ["Not Paid", "Not Paid"];
    }
  } catch (error) {
    console.error(`Error loading data for Quarter ${quarter}:`, error);
    this.paymentData[pid][quarter] = ["Error", "Error"];
  }
}


    return this.paymentData[pid];
  } catch (error) {
    console.error('Error loading payment data:', error);
    if (!this.paymentData[pid]) this.paymentData[pid] = {};
    for (let quarter = 1; quarter <= 4; quarter++) {
      this.paymentData[pid][quarter] = ["Error", "Error"];
    }
    return this.paymentData[pid];
  }
},


async calculateRelivedPayments() {
    try {
      this.propertyde = (
        await axios.get('/sabapropid/' + this.sabha + '/' + this.CatObj.propsearch)
      ).data;

      let assessval = this.propertyde[0].yearly_value; // yearly value
      let relifrate = this.cusObj.relper; // Relief rate percentage
      if (relifrate) {
        let yeralyval = (assessval * (parseFloat(relifrate) / 100)).toFixed(2); // New yearly value
        let newyearlyval= (assessval-yeralyval).toFixed(2)
        let oneQuatervalue = parseFloat(newyearlyval / 4); // Value of one quarter
        let threeQuartersum = parseFloat(oneQuatervalue * 3); // Value of 3 quarters

        // Update values in quarter payment textboxes
        this.cusObj.fq = oneQuatervalue.toFixed(2);
        this.cusObj.sq = oneQuatervalue.toFixed(2);
        this.cusObj.tq = oneQuatervalue.toFixed(2);
        this.cusObj.foq = (newyearlyval - threeQuartersum).toFixed(2);
      }
    } catch (error) {
      console.error('Error fetching property details:', error);
    }
  },
 
  handleCheckboxChange() {
// Reset error message
this.errorMessage = "";

if (!this.Annualchecked) {
  // Clear values when checkbox is unchecked
  this.cusObj.yearlyval = "";
  this.cusObj.discval = "";
  //  this.amountpaid='';
  return; // Exit early as no further actions are needed
}

// Ensure propDetails is loaded and not empty
if (!this.propDetails || !this.propDetails[0]) {
  this.errorMessage = "Property details are not available.";
  this.Annualchecked = false;
  return;
}

// Set yearly value
const yearly_val = this.propDetails[0].yearly_value;
this.cusObj.yearlyval = yearly_val;

// Get the current date
const today = new Date(this.serverDate);
const strippedCurrentDate = new Date(today.getFullYear(), today.getMonth(), today.getDate());
const january31 = new Date(today.getFullYear(), 0, 31); // January 31 of the current year
    // const january31 = new Date(today.getFullYear(), 10, 21); // for checking purpose

if (this.Annualchecked) {
  if (strippedCurrentDate > january31) {
    this.errorMessage = "The time to pay the full annual amount has passed.";
    this.Annualchecked = false; // Reset checkbox if error
    
  } else {
    // Perform discount calculation
    this.calculateDiscount(yearly_val);
    // this.Annualchecked = true;
    // this.amountpaid=this.amountpaidget;
  }
  
}
},

calculateDiscount(yearly_val) {
const DISCOUNT_PERCENTAGE = 10; // Use a constant for maintainability

const discount_amount = (yearly_val * (DISCOUNT_PERCENTAGE / 100)).toFixed(2);
const discount_value = (yearly_val - discount_amount).toFixed(2);

// Update the customer object with the discounted value
// this.cusObj.discval = discount_value;
this.cusObj.discval = discount_amount;
// this.cusObj.payamountqaurter=discount_value;
if(parseFloat(this.cusObj.arre)<0){
 
  this.cusObj.payamountqaurter=(parseFloat(discount_value)+parseFloat(this.cusObj.arre)).toFixed(2);
}else{
  this.cusObj.payamountqaurter=parseFloat(discount_value).toFixed(2);
}
// this.amountpaid=discount_value;

// console.log("Discount applied:", discount_value);
},
qoneChecked() {
this.qOneErrorMessage = "";

// Ensure property details are available
if (!this.propDetails || !this.propDetails[0]) {
  this.qOneErrorMessage = "Property details are not available.";
  this.qonecheck = false;
  return;
}

  this.cusObj.fq = this.propDetails[0].q_one_val || 0;
},

qtwoChecked() {
this.qtwoErrorMessage = "";

if (!this.propDetails || !this.propDetails[0]) {
  this.qtwoErrorMessage = "Property details are not available.";
  this.qtwocheck = false;
  return;
}

// Assign initial quarter payment directly
this.cusObj.sq = this.propDetails[0].q_two_val;
},
qthreeChecked(){
this.qthreeErrorMessage=""

if (!this.propDetails || !this.propDetails[0]) {
  this.qthreeErrorMessage = "Property details are not available.";
  this.qthrecheck = false;
  return;
}
this.cusObj.tq = this.propDetails[0].q_three_val
},
qfourChecked(){

this.qfourerrorMessage=""

if (!this.propDetails || !this.propDetails[0]) {
  this.qfourerrorMessage = "Property details are not available.";
  this.qfourcheck = false;
  return;
}
this.cusObj.foq = this.propDetails[0].q_four_val

},

calQuarterDiscount(quarterPayment, currentDate) {
const march31 = new Date(currentDate.getFullYear(), 2, 31); // March 31
const jan31 = new Date(currentDate.getFullYear(), 0, 31); // january 31
const june30 = new Date(currentDate.getFullYear(), 5, 30); // June 30
const sept30 = new Date(currentDate.getFullYear(), 8, 30); // Sept 30
const dec31 = new Date(currentDate.getFullYear(), 11, 31); // Dec 31

const warrantRate = this.propDetails?.[0]?.warrant_cost_rate || 0;
const strippedCurrentDate = new Date(currentDate.getFullYear(), currentDate.getMonth(), currentDate.getDate());

if (strippedCurrentDate <= march31) {
  let discountedValue=quarterPayment;
  let discount=0;
  if (strippedCurrentDate <= jan31) {
    const discountRate = 5; // Discount for Jan-Mar
    discount= (quarterPayment * (discountRate / 100)).toFixed(2);
    discountedValue = (quarterPayment - discount).toFixed(2);
  }
  const fine = "0";
  return [discount, discountedValue, fine];
} else if (strippedCurrentDate > march31 && strippedCurrentDate <= june30) {
  const fine = (quarterPayment * (warrantRate / 100)).toFixed(2);
  const paymentWithFine = (parseFloat(quarterPayment) + parseFloat(fine)).toFixed(2);
  const discount = "0";
  return [discount, paymentWithFine, fine];
} else if (strippedCurrentDate > june30 && strippedCurrentDate <= sept30) {
  const fine = (quarterPayment * (warrantRate / 100)).toFixed(2);
  const paymentWithFine = (parseFloat(quarterPayment) + parseFloat(fine)).toFixed(2);
  const discount = "0";
  return [discount, paymentWithFine, fine];
} else if (strippedCurrentDate > sept30 && strippedCurrentDate <= dec31) {
  const fine = (quarterPayment * (warrantRate / 100)).toFixed(2);
  const paymentWithFine = (parseFloat(quarterPayment) + parseFloat(fine)).toFixed(2);
  const discount = "0";
  return [discount, paymentWithFine, fine];
} else {
  // Handle invalid dates if necessary
  return ["0", quarterPayment, "0"];
}
},
calSecondQuarterValues(quarterPayment, currentDate) {
const june30 = new Date(currentDate.getFullYear(), 5, 30); // June 30
const aprill30 = new Date(currentDate.getFullYear(), 3, 30);   // July 1
const july1 = new Date(currentDate.getFullYear(), 6, 1);   // July 1
const dec31 = new Date(currentDate.getFullYear(), 11, 31); // Dec 31

const warrantRate = this.propDetails?.[0]?.warrant_cost_rate || 0;

// Strip time from currentDate for accurate comparison
const strippedCurrentDate = new Date(currentDate.getFullYear(), currentDate.getMonth(), currentDate.getDate());

if (strippedCurrentDate <= june30) { // Discount applies up to and including June 30
  let discountedValue=quarterPayment;
  let discount=0;
  if (strippedCurrentDate <= aprill30) { 
  const discountRate = 5; // Discount for Jan-Mar
   discount = (quarterPayment * (discountRate / 100)).toFixed(2);
   discountedValue = (quarterPayment - discount).toFixed(2);
  }
  const fine = "0"; // No fine
  return [discount, discountedValue, fine];
} else if (strippedCurrentDate >= july1 && strippedCurrentDate <= dec31) { // Fine applies from July 1 onward
  const fine = (quarterPayment * (warrantRate / 100)).toFixed(2);
  const paymentWithFine = (parseFloat(quarterPayment) + parseFloat(fine)).toFixed(2);
  const discount = "0"; // No discount
  return [discount, paymentWithFine, fine];
} else {
  // Handle invalid dates if necessary
  return ["0", quarterPayment.toFixed(2), "0"];
}
},

calThirdQuarterValues(quarterPayment, currentDate){

const sept30 = new Date(currentDate.getFullYear(), 8, 30); // Sept 30
const july31 = new Date(currentDate.getFullYear(), 5, 31); // Sept 30
const dec31 = new Date(currentDate.getFullYear(), 11, 31); // Dec 31
const oct1 = new Date(currentDate.getFullYear(), 9, 1); // oct1

const warrantRate = this.propDetails?.[0]?.warrant_cost_rate || 0;
const strippedCurrentDate = new Date(currentDate.getFullYear(), currentDate.getMonth(), currentDate.getDate());

if (strippedCurrentDate <= sept30) {
  let discountedValue=quarterPayment;
  let discount=0;
  if (strippedCurrentDate <= july31) {
  const discountRate = 5; // Discount for Jan-Mar
   discount = (quarterPayment * (discountRate / 100)).toFixed(2);
   discountedValue = (quarterPayment - discount).toFixed(2);
  }
  const fine = "0";
  return [discount, discountedValue, fine];
} else if (strippedCurrentDate >= oct1 && strippedCurrentDate <= dec31) {
  const fine = (quarterPayment * (warrantRate / 100)).toFixed(2);
  const paymentWithFine = (parseFloat(quarterPayment) + parseFloat(fine)).toFixed(2);
  const discount = "0";
  return [discount, paymentWithFine, fine];
}else {
  // Handle invalid dates if necessary
  return ["0", quarterPayment.toFixed(2), "0"];
}
},
calFourthQuarterValues(quarterPayment, currentDate){
const dec31 = new Date(currentDate.getFullYear(), 11, 31); // Dec 31
const oct31 = new Date(currentDate.getFullYear(), 9, 31); // Dec 31
const strippedCurrentDate = new Date(currentDate.getFullYear(), currentDate.getMonth(), currentDate.getDate());


if (strippedCurrentDate <= dec31) {
  let discountedValue=quarterPayment;
  let discount=0;
  if (strippedCurrentDate <= oct31) {
  const discountRate = 5; // Discount for Jan-Mar
   discount = (quarterPayment * (discountRate / 100)).toFixed(2);
   discountedValue = (quarterPayment - discount).toFixed(2);
  }
  const fine = "0";
  return [discount, discountedValue, fine];
} else {
  // Handle invalid dates if necessary
  return ["0", quarterPayment.toFixed(2), "0"];
}
},

    resetCheckErr2: function () {
      this.cusErrObj.fineheadErr = [];
      this.cusErrObj.descrErr =[];
      this.cusErrObj.iheadErr=[];
      this.cusErrObj.arreheadErr=[];
      this.cusErrObj.amountpaidErr=[];
     },
     checkEmptyErr2: function () {
         for (var typeErr in this.cusErrObj) {
             if (this.cusErrObj[typeErr].length != 0) {
                 return false;
             }
         }
         return true;
     },
     checkForm2: function () {
      this.resetCheckErr2();
      // Check if fine vote is required
          if (this.totalFine !== "0.00" && !this.cusObj.finevot) {
            this.cusErrObj.fineheadErr.push("required.");
          }
         if (!this.cusObj.headid && parseFloat(this.cusObj.arre) <=0) {
             this.cusErrObj.iheadErr.push("required");
         }
        //  if (!this.cusObj.headid && parseFloat(this.cusObj.arre) ==0) {
        //      this.cusErrObj.iheadErr.push("required");
        //  }
         if (!this.cusObj.arreasvot && parseFloat(this.cusObj.arre) >0) {
             this.cusErrObj.arreheadErr.push("required");
         }
         if (!this.amountpaid && parseFloat(this.cusObj.arre) <=0) {
             this.cusErrObj.amountpaidErr.push("required");
         }
     },
     async addToTemp() {
    this.checkForm2();
    if (!this.checkEmptyErr2()) 
    return;

    try {
     const paymentmonth =  parseInt(new Date(this.serverDate).getMonth())+1
    const paymentyear = new Date(this.serverDate).getFullYear()
        const totaltopaid = parseFloat(this.amountpaidget);
        const amounttopaid = parseFloat(this.amountpaid);
        // let savamount = this.Annualchecked ? this.cusObj.yearlyval : parseFloat(this.calQuaPay()).toFixed(2);
        this.assdiscount = this.Annualchecked ? this.cusObj.discval : this.totalDiscount;
        
      let addamount=parseFloat(this.amountpaid)+parseFloat(this.assdiscount)
        const data = {
            sabha_code: this.sabha,
            cus_nic: this.ownerdetails[0]?.property_id || "",
            cus_name: this.cusObj.name,
            cus_contact: this.ownerdetails[0]?.cus_contact || "",
            cus_address: this.cusObj.address,
            sb_rate_head: this.cusObj.headid,
            description: this.descriptionText,
            // amount: savamount,
            amount: addamount.toFixed(2),
            stamp: "0",
            discount: this.assdiscount,
            shoptotalarrears: "0",
            paymonth: `${paymentmonth}-${paymentyear}`,
            vat: "0",
            shopdid: this.ownerdetails[0]?.property_id || "0",
            // shopdid: this.CatObj.propsearch,
            date: this.formattedDate(),
            sub_nic: this.nic,
        };
    this.saveTemporyPayment(this.ownerdetails[0].property_id);

        if (parseFloat(this.cusObj.arre) === 0 || parseFloat(this.cusObj.arre) < 0) {
            await axios.post("/receipt/", data);
        }
        
        if (parseFloat(this.cusObj.arre) > 0) {
          let rearr=parseFloat(this.cusObj.arre);

          if (amounttopaid < parseFloat(this.cusObj.arre)) {
           rearr=parseFloat(this.cusObj.arre)-amounttopaid;
           const exessdata = { property_id: this.ownerdetails[0]?.property_id || "", sabha_code: this.sabha, amount: rearr };
           await axios.post("/savexcess/", exessdata);
          }
          if (amounttopaid > parseFloat(this.cusObj.arre)) {
           rearr=parseFloat(this.cusObj.arre)-amounttopaid;
           const exessdata = { property_id: this.ownerdetails[0]?.property_id || "", sabha_code: this.sabha, amount: rearr };
           await axios.post("/savexcess/", exessdata);
          }
    
        
            const arrearsdata = { ...data, amount: amounttopaid.toFixed(2),sb_rate_head: this.cusObj.arreasvot };
            await axios.post("/receipt/", arrearsdata);
        }

        if (this.totalFine !== "0.00") {
            const finedata = { ...data, description: 'Fine Amount', amount: this.totalFine, sb_rate_head: this.cusObj.finevot, discount: "0" };
            await axios.post("/receipt/", finedata);
        }

        if (amounttopaid > totaltopaid) {
            const exessamount = (totaltopaid - amounttopaid).toFixed(2);
            const exessdata = { property_id: this.ownerdetails[0]?.property_id || "", sabha_code: this.sabha, amount: exessamount };
            await axios.post("/savexcess/", exessdata);
        }
        if (amounttopaid <totaltopaid) {
            const exessamount = (totaltopaid - amounttopaid).toFixed(2);
            const exessdata = { property_id: this.ownerdetails[0]?.property_id || "", sabha_code: this.sabha, amount: exessamount };
            await axios.post("/savexcess/", exessdata);
        }
       

        this.$refs.alert.showAlert('success', 'PIV Added Successfully!');
        this.allreceipt = (await axios.get('/ownpiv/' + this.nic + "/" + this.sabha)).data;
        // this.resetForm();
        this.showtblpro = false;

    } catch (error) {
        console.error("Error during API calls:", error);
        this.$refs.alert.showAlert('error', 'An error occurred while adding the PIV. Please try again.');
    }
},


// }

 },
 components: {
      VueBasicAlert
  }
};

</script>

<style scoped>
.relief-row {
display: flex;
align-items: center;
gap: 10px; /* Adds spacing between items */
}

.relief-row input[type="text"] {
width: auto;
/* box-shadow: 0 1rem 1rem rgba(0, 0, 0, 0.05); */
 border: 0.1rem solid rgba(0, 0, 0, 0.2);
 padding: 0.6rem;
 border-radius: 0.5rem;
 background: #f7f7f7;
  /* padding: 2rem 1.2rem; */
  font-size: 1.3rem;
  color: #130f40;
}

.relief-row input[type="button"] {
width: auto;
background-color: #022e2a;
color:#fff;
padding: 8px;
border-radius: 8px;

}
.ttable {
   
   width: 100%;
   border-width:2px;
   border-color : #130f40;
   /* border-style: solid; */
   font-size: 1.2rem;
   text-align: center;
   /* background-color :white; */
   /* padding-left: 3rem; */
    /* height: 3rem; */
    padding: 2%;
}
th  {
   text-align: center;
}
.ttable, th, td {
border: 1px solid;
}
.row1 {
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 1rem;
}
.close-btn {
/* align-items: right; */
background-color:rgb(121, 23, 12);
border: none;
font-size: 15px;
font-weight: bold;
cursor: pointer;
color: #f8f4f4;
transition: color 0.3s ease, transform 0.2s ease;
padding: 8px;
border-radius: 8px;
width:6%;
height: 4%;
text-align: center;
}

.close-btn:hover {
color: rgb(220, 18, 18);
transform: scale(1.2);
background-color:  #022e2a; 
}

.close-btn:focus {
outline:none; /* Remove default focus outline */
}
input[type="button"] {
 background: none;
 color: inherit;
 border: none;
 padding: 0;
 font: inherit;
 cursor: pointer;
 outline: inherit;
}
/* shroff form container */
.Shop-container {
 background-color: #ffffff09;
 min-height: 90vh;
 padding: 1rem 2%;
 font-size: 16px;
}

/* ongoing */
.Shop-container .Shop-form-container {
 background: #fff;
 /* width: 100% !important; */
 
}

.Shop-container .Shop-form-container form {
 /* position: relative;
 left: 40%;
 transform: translate(-50%, 0%); */
 /* max-width: 70rem; */
 width: 100%;
 box-shadow: 0 1rem 1rem rgba(0, 0, 0, 0.05);
 border: 0.1rem solid rgba(0, 0, 0, 0.2);
 padding: 2rem;
 border-radius: 0.5rem;
 animation: fadeUp 0.4s linear;
 
}

.Shop-container .Shop-form-container form h3 {
 padding-bottom: 1rem;
 font-size: 2rem;
 text-transform: uppercase;
 color: #130f40;
 margin: 0;
}

.Shop-container .Shop-form-container form .form-control {
  margin: 0.7rem 0;
 border-radius: 0.5rem;
 background: #f7f7f7;
 /* padding: 2rem 1.2rem; */
  font-size: 1.3rem; 
 color: #130f40;
 text-transform: none;
 width: 100%;
 border: margin-bottom; 
} 

.Shop-container .Shop-form-container form label {
  font-size: 1.2rem;
 margin: 0;
 padding: 0;
 float:left;
}

.Shop-container .Shop-form-container form span {
 font-size: 18px;
 padding-left: 5px;
 padding-right: 40px;
}

.Shop-container .Shop-form-container form .btn {
 /* margin: 1rem 0; */
 width: 25%;
 text-align: center;
 font-size: small;
 background-color : #032a2c;
  height: 3rem;
  color: #fffbfb;

 
}
/* .Shop-container .Shop-form-container form .table {
 margin: 1rem 0;
 width: 100%;
 text-align: center;
 font-size: small;
 background-color : #e9e4e9;
  height: 3rem;
 
} */
.Shop-container .Shop-form-container form .Addbtn {
 /* margin: 1rem 0; */
 width: 5rem;
 /* height: 2rem; */
 /* text-align: center; */
 /* background-color : #af74a7; */
 /* vertical-align: bottom; */
 /* align-items: bottom; */
 padding-top: 1.9rem;
 padding-left: 3rem;
 
}

.Shop-container .Shop-form-container form p {
 padding-top: 1rem;
 font-size: 1.3rem;
 color: rgb(243, 47, 47);
 margin: 0;
}

.Shop-container .Shop-form-container form p a {
 color: #27ae60;
}

.Shop-container .Shop-form-container form p a:hover {
 color: #130f40;
 text-decoration: underline;
}

.Shop-container .Shop-form-container form .form-group {
 margin: 0;
 font-size: 1.2rem;
}

.Shop-container .Shop-form-container form .form-group .error-mess {
 font-size: 1.5rem;
 position: relative;
 color: rgb(243, 47, 47);
 margin: 0;
 /* padding: 2rem 1.2rem; */
}
/* second form */
.register-container {
  padding-top: 1.0rem;
  font-size: 16px;
   /* min-height: 72.3vh; */
}

.register-container .register-form-container {
  background: #fff;

}

.register-container .register-form-container form {
  position: relative;
  left: 50%;
  transform: translate(-50%, 0%);
  /* max-width: 98rem; */
  width: 100%;
  box-shadow: 0 1rem 1rem rgba(0, 0, 0, 0.05);
  border: 0.1rem solid rgba(0, 0, 0, 0.2);
  padding: 2rem;
  border-radius: 0.5rem;
  animation: fadeUp 0.4s linear;
}

.register-container .register-form-container form h3 {
  padding-bottom: 0.5rem;
  font-size: 1.9rem;
  text-transform: uppercase;
  color: #130f40;
  /* margin: 0; */
  text-align: center;
  
}

.register-container .register-form-container form .form-control {
  margin: 0.7rem 0;
  border-radius: 0.5rem;
  background: #f7f7f7;
  /* padding: 2rem 1.2rem; */
  font-size: 1.3rem;
  color: #130f40;
  text-transform: none;
  width: 100%;
  border: margin-bottom; 
}

.register-container .register-form-container form .form-radio {
  margin: 2rem 0%;
  border-radius: 0.5rem;
  background: #f7f7f7;
 padding-bottom: 1rem;
  font-size: 10rem;
  color: #130f40;

  width: 10%;
  border: none;
  margin-left: 10%;
}
.register-container .register-form-container form label {
  font-size: 1.2rem;
  margin: 0;
  padding: 0;
}

.register-container .register-form-container form span {
  font-size: 25px;
  /* padding-left: 5px;
  padding-right: 40px; */
  text-align: center;
  color: #022e2a;
}
.register-container .register-form-container form .form-group  .error-mess {
  font-size: 1rem;
  position: relative;
  color: rgb(243, 47, 47);
  margin: 0;
  padding: 0;
  width: 90%;

} 
.register-container .register-form-container form .bbtn {
 margin: 1% 0;
 width: 100%;
 text-align: center;
 font-size: small;
 background-color : #032a2c;
 color: #f7f7f7;
  height: 3rem;
  border-radius: 8px;
}

</style>