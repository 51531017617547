<template>
    
    <div class="form-group">
        <div class="row">
            <div class="col-4"></div>
                <div class="col-4">
                    <label for="iStamp">Date
                    </label>
                    <input type="date" @input="onChange()" name="idate"  id="idate" class="form-control" 
                        v-model="shroffObj.idate" />
                </div>
            </div>
        </div>

        <div class="invoice-container" >
            <div class="invoice-form-container" id="ShroffContent">
                
                <form id="invoiceForm" novalidate autocomplete="off">
                    <div id="Printcontent">
                    <h5>ප්‍රා.ස.2</h5>
                    <div class="heading"> 
                        <h4 style="text-align: center; padding-top: 0px; padding-bottom: 0px;">Daily Notebook On Shroff Income | සරප් ලැබීම් පිළීබඳ දෛනික සටහන් පොත </h4>
                       <h4>{{ sabhadetail.sb_name_en }}</h4> 
                       <h4>{{ this.shroffObj.idate}} </h4> 
                    </div>
                   
   
                    <div >
                    <table style="text-align: center; border-width: 1px ; border-style: solid; border-color : #130f40; border-collapse: collapse; font-size: small;width: 100%; ">
                    <!-- <thead>
                       
                    </thead>
                    <tbody> -->
                        <tr >   
                            <th style="text-align: center; border-width: 1px ; border-style: solid; border-color : #130f40; border-collapse: collapse; font-size: small; ">Invoice Number <br> ලදුපත් අංකය</th>
                            <th style="text-align: center; border-width: 1px ; border-style: solid; border-color : #130f40; border-collapse: collapse; font-size: small; ">Cash <br> මුදල්</th>
                            <th style="text-align: center; border-width: 1px ; border-style: solid; border-color : #130f40; border-collapse: collapse; font-size: small; ">Cheque <br> චෙක්පත්</th>
                            <!-- <th style="text-align: center; border-width: 1px ; border-style: solid; border-color : #130f40; border-collapse: collapse; font-size: small; ">Cheque <br> චෙක්</th> -->
                            <th style="text-align: center; border-width: 1px ; border-style: solid; border-color : #130f40; border-collapse: collapse; font-size: small; ">Cross <br> හරස් සටහන්</th>
                            <th style="text-align: center; border-width: 1px ; border-style: solid; border-color : #130f40; border-collapse: collapse; font-size: small; ">Remittances <br> ප්‍රේෂණ</th>
                            <th style="text-align: center; border-width: 1px ; border-style: solid; border-color : #130f40; border-collapse: collapse; font-size: small;">Total <br> එකතුව</th>
                            
    
                        </tr>
                        <tr v-for=" s in dailysummary" :key="s.id">
                           
                            <td style="text-align: center; border-width: 1px ; border-style: solid; border-color : #130f40; border-collapse: collapse; font-size: small; "><router-link :to="{name:'InvoiceView',params:{data:s.invoice_num,pay_type:s.type,chq_num:s.cheque_no}}">{{ s.invoice_num }}</router-link></td>
                            
                            <td style="text-align: right; border-width: 1px ; border-style: solid; border-color : #130f40; border-collapse: collapse; font-size: small; " v-if="$formatNumber(s.cash_total)==0 && s.invoice_status==0"></td>
                            <td style="text-align: right; border-width: 1px ; border-style: solid; border-color : #130f40; border-collapse: collapse; font-size: small; " v-else>{{ $formatNumber(s.cash_total) }}</td>
                            <td style="text-align: right; border-width: 1px ; border-style: solid; border-color : #130f40; border-collapse: collapse; font-size: small; " v-if="$formatNumber(s.cheque_total)==0 && s.invoice_status==0"></td>
                            <td style="text-align: right; border-width: 1px ; border-style: solid; border-color : #130f40; border-collapse: collapse; font-size: small; " v-else>{{ $formatNumber(s.cheque_total) }}</td>
                            <td style="text-align: right; border-width: 1px ; border-style: solid; border-color : #130f40; border-collapse: collapse; font-size: small; " v-if="$formatNumber(s.cross_total)==0 && s.invoice_status==0"></td>
                            <td style="text-align: right; border-width: 1px ; border-style: solid; border-color : #130f40; border-collapse: collapse; font-size: small; " v-else>{{ $formatNumber(s.cross_total) }}</td>
                            <td style="text-align: right; border-width: 1px ; border-style: solid; border-color : #130f40; border-collapse: collapse; font-size: small; " v-if="$formatNumber(s.directd_total)==0 && s.invoice_status==0"></td>
                            <td style="text-align: right; border-width: 1px ; border-style: solid; border-color : #130f40; border-collapse: collapse; font-size: small; " v-else>{{ $formatNumber(s.directd_total) }}</td>
                            <td style="text-align: right; border-width: 1px ; border-style: solid; border-color : #130f40; border-collapse: collapse; font-size: small;"  v-if="s.invoice_status==0">{{ $formatNumber(s.total_amount) }}</td>
                            <td style="text-align: right; border-width: 1px ; border-style: solid; border-color : #130f40; border-collapse: collapse; font-size: small; " v-else colspan="2">Cancled</td>
                        </tr>
                        <!-- <tr v-for="s in dailysummary" :key="s.id">
    <td style="text-align: center; border: 1px solid #130f40; border-collapse: collapse;">
        <router-link v-if="s.invoice_status !== 1" :to="{name:'InvoiceView', params:{data:s.invoice_num}}">{{ s.invoice_num }}</router-link>
        <span v-else>{{ s.invoice_num }}</span>
    </td>
    <td v-if="s.invoice_status == 1" colspan="2" style="text-align: right; border: 1px solid #130f40; border-collapse: collapse;">Canceled</td>
    <td v-else-if="s.cash_total !== 0 || s.cheque_total !== 0" style="text-align: right; border: 1px solid #130f40; border-collapse: collapse;">
        {{ s.cash_total }}
    </td>
    <td v-else style="text-align: right; border: 1px solid #130f40; border-collapse: collapse;">
        {{ s.cheque_total }}
    </td>
    <td v-if="s.invoice_status == 0" style="text-align: right; border: 1px solid #130f40; border-collapse: collapse;">{{ s.total_amount }}</td>
</tr> -->
                    <!-- </tbody> -->
                </table>
              
                <br>
                <div class="heading"> 
                    <h4>Bank Deposit on {{ this.shroffObj.idate}}| {{ this.shroffObj.idate}} දින මුදල් බැංකුවේ තැන්පත් කිරීම</h4> 
                </div>
                <table style="text-align: center; border-width: 1px ; border-style: solid; border-color : #130f40; border-collapse: collapse; font-size: small;width: 100%; ">
                    <thead>
                        <tr >
                            <th ></th>   
                            <th style="text-align: center; border-width: 1px ; border-style: solid; border-color : #130f40; border-collapse: collapse; font-size: small; ">Cash <br> මුදල්</th>
                            <th style="text-align: center; border-width: 1px ; border-style: solid; border-color : #130f40; border-collapse: collapse; font-size: small; ">Cheque <br> චෙක්පත්</th>
                            <th style="text-align: center; border-width: 1px ; border-style: solid; border-color : #130f40; border-collapse: collapse; font-size: small; ">Cross <br> හරස් සටහන්</th>
                            <th style="text-align: center; border-width: 1px ; border-style: solid; border-color : #130f40; border-collapse: collapse; font-size: small; ">Remittances <br> ප්‍රේෂණ</th>
                            <th style="text-align: center; border-width: 1px ; border-style: solid; border-color : #130f40; border-collapse: collapse; font-size: small; ">Total <br> එකතුව</th>
                            <th style="text-align: center; border-width: 1px ; border-style: solid; border-color : #130f40; border-collapse: collapse; font-size: small; ">Other <br> වෙනත් කරුණු</th>
    
                        </tr>
                    </thead>
                    <tbody>
                        <tr aria-rowspan="2">
                            <td style="text-align: center; border-width: 1px ; border-style: solid; border-color : #130f40; border-collapse: collapse; font-size: small; ">Total |එකතුව</td>
                            <td style="text-align: center; border-width: 1px ; border-style: solid; border-color : #130f40; border-collapse: collapse; font-size: small; ">{{ $formatNumber(calculateSum()[0]) }}</td>
                            <td style="text-align: center; border-width: 1px ; border-style: solid; border-color : #130f40; border-collapse: collapse; font-size: small; ">{{ $formatNumber(calculateSum()[1]) }}</td>
                            <td style="text-align: center; border-width: 1px ; border-style: solid; border-color : #130f40; border-collapse: collapse; font-size: small; ">{{ $formatNumber(calculateSum()[4]) }}</td>
                            <td style="text-align: center; border-width: 1px ; border-style: solid; border-color : #130f40; border-collapse: collapse; font-size: small; ">{{ $formatNumber(calculateSum()[3]) }}</td>
                            <td style="text-align: center; border-width: 1px ; border-style: solid; border-color : #130f40; border-collapse: collapse; font-size: small; ">{{ $formatNumber(calculateSum()[2]) }}</td>
                            <td style="text-align: center; border-width: 1px ; border-style: solid; border-color : #130f40; border-collapse: collapse; font-size: small; ">{{  }}</td>
                        </tr>
            
                    </tbody>
                </table>
                </div>
                <br>
                <h5>
                    Shroff Signature & Date | සරප්ගේ අත්සන හා දිනය : <br><br>
                    Signature and Date of Inspecting Officers | පරික්ෂා කළ නිලධාරීන්ගේ අත්සන හා දිනය : <br><br>
                    If a check is dishonoured, the Cheque number, value of the cheque and date credited to proceed | චෙක් පතක් අඟරු වුනි නම් වෙක්පත් අංකය, චෙක්පතේ වටිනාකම හා ආදායමට බැර කල දිනය :
                </h5>
            </div>
                <div class="btnrow">
                    <div class="">
                        <input type="button" value="Print" class=" btn" @click="printContent">
                    </div>
                </div>
                </form>
            
            <br>

            </div>
        </div>
    </template>
    <script>
import axios from 'axios';
import { RouterLink } from 'vue-router';
export default {
    name: "ShroffIncome",
    data() {
        return {
            shroffObj: { idate: "" },
            nic: "",
            sabha: "",
            userLevel: "",
            date: new Date(),
            defaultdate: "",
            dailysummary: [],
            sabhadetail: [],
        };
    },
    created() {
        this.getDataFromSessionStorage();
    },
    computed: {},
    methods: {
        formattedDate() {
            const day = this.date.getDate().toString().padStart(2, "0");
            const month = (this.date.getMonth() + 1)
                .toString()
                .padStart(2, "0");
            const year = this.date.getFullYear().toString();
            // this.defaultdate =`${year}-${month}-${day}`
            return `${year}-${month}-${day}`;
        },
        async getDataFromSessionStorage() {
            const data = JSON.parse(sessionStorage.getItem('userData'));
            if (data) {
                this.nic = data.nic;
                this.sabha = data.sabha;
                this.userLevel = data.userLevel;
            }
            let sabhaCode = this.sabha;
            //    let Sabhadata = await axios.get('/subheads/'+ this.nic +"/"+ sabhaCode)
            //    this.sabhaIncomeHead = Sabhadata.data
            this.sabhadetail = (await axios.get('/pra_sabha/' + this.sabha)).data;
            // this.defaultdate = this.formattedDate();
            this.defaultdate = new Date();
            let sumdata1 = await axios.get('/shroffsumdate/'+sabhaCode+"/" +this.defaultdate+"/"+this.nic);
            // let sumdata1 = await axios.get('/invoicesum/' + sabhaCode + "/" + this.defaultdate);
            this.dailysummary = sumdata1.data;
        },
        calculateSum: function () {
            let i = 0;
            let money = 0;
            let transfer = 0;
            let total = 0;
            let direct = 0;
            let cross=0;
            // let subtotal =0;
            for (i = 0; i < this.dailysummary.length; i++) {
                if(this.dailysummary[i].invoice_status==0){
                money = money + (parseFloat(this.dailysummary[i].cash_total));
                transfer = transfer + (parseFloat(this.dailysummary[i].cheque_total));
                total = total + (parseFloat(this.dailysummary[i].total_amount));
                direct = direct + (parseFloat(this.dailysummary[i].directd_total));
                cross = cross + (parseFloat(this.dailysummary[i].cross_total));
                //   subtotal = total + transfer + (parseInt(this.dailysummary[i].total_amount))
            }
        }
            return [money.toFixed(2), transfer.toFixed(2), total.toFixed(2),direct.toFixed(2),cross.toFixed(2)];
        },
        async onChange() {
            // get data from invoice
            // let sumdata = await axios.get('/invoicesum/' + this.sabha + "/" + this.shroffObj.idate);
            let sumdata = await axios.get('/shroffsumdate/'+this.sabha+"/" +this.shroffObj.idate+"/"+this.nic);
            this.dailysummary = sumdata.data;
            // this.shroffObj.idate = "";
        },
        printContent() {
            const prtHtml = document.getElementById('Printcontent').innerHTML;
               const WinPrint = window.open('', '', 'left=0,top=0,width=800,height=900,toolbar=0,scrollbars=0,status=0');
                WinPrint.document.write(`    
        ${prtHtml}
    `);
                WinPrint.focus();
                WinPrint.print();
                WinPrint.close();
        }
    },
    components: { RouterLink }
}
</script>
<style>
.ttable {
   
   width: 100%;
   border-width:1px;
   border-color : #130f40;
   /* border-style: solid; */
   font-size: 1rem;
   /* background-color :white; */
   /* padding-left: 3rem; */
    /* height: 3rem; */
    border-collapse: collapse;
}
th  {
   text-align: center;
}
.ttable, th, td {
border: 1px solid;
text-align: center;
}
.form-group{
    margin: 1rem;
    font-size: 20px;
    padding-top: 0rem;
    padding-bottom: 0rem;
}
.invoice-container .invoice-form-container .table{
    width: 100%; 
   
}
.invoice-container {
    background-color: #fff;
    /* height: 100vh; */
    padding: 1rem 5%;
    font-size: 16px;
    align-content: center;
    padding-top: 0rem;
}
.invoice-container .invoice-form-container {
    background: #fff;

}

.invoice-container .invoice-form-container form {
    position: relative;
    left: 50%;
    transform: translate(-50%, 0%);
    /* max-width: 70rem; */
    width: 100%;
    box-shadow: 0 1rem 1rem rgba(0, 0, 0, 0.05);
    border: 0.1rem solid rgba(0, 0, 0, 0.2);
    padding: 2rem;
    border-radius: 0.5rem;
    animation: fadeUp 0.4s linear;
    padding-left: 5rem;
}
.invoice-container .invoice-form-container form h3 {
    /* padding-bottom: 1rem; */
    font-size: 1.7rem;
    text-transform: uppercase;
    color: #130f40;
    margin: 0;
}

.invoice-container .btn {
    background: rgb(223, 80, 14);;
    color: rgb(240, 234, 231);
    border: 1rem;
    padding: 1rem;
    font: inherit;
    cursor: pointer;
    outline: 1rem;
    text-align: center;
   width: 10rem;
}
.invoice-container .btnrow{
   align-content: center;
   padding-left: 25rem;
}
.btn {
    background: rgb(223, 80, 14);;
    color: rgb(240, 234, 231);
    border: 1rem;
    padding: 1rem;
    font: inherit;
    cursor: pointer;
    outline: 1rem;
    text-align: center;
   width: 10rem;
}
.form-group {
    margin:5rem;
    font-size: 1.3rem;
} 


</style>
   